import styled from 'styled-components';

import btnBGImage from '@src/images/limitado/mobile/AboutTheCourse/bg-btn.png';

export const Container = styled.div`
  ${tw`p-8 mt-20`};
`;

export const ContentTitle = styled.div`
  ${tw`text-center mb-20`};
`;

export const ContentTitleText = styled.h1`
  ${tw`text-center text-4xl`};
`;

export const ContentTitleSeparator = styled.img`
  ${tw``};
`;

export const Content = styled.div`

`;

export const Item = styled.div`
  ${tw`bg-theme-background-block mb-20 p-5 pb-10`};
`;

export const IconImage = styled.img`
  ${tw``};
  margin-top: -50px;
`;

export const Title = styled.h2`
  ${tw`mt-5 text-2xl`}
`;

export const Text = styled.p`
  ${tw`mt-5 text-base text-theme-text leading-normal`};
  font-size: 15px;
`;

export const ButtonContent = styled.div`
  ${tw`text-center`}
`;

export const ButtonCTA = styled.a`
  ${tw`
    bg-theme-text-purple 
    hover:bg-gray-400
    hover:text-theme-background
    text-white 
    rounded-full 
    font-bold py-5 
    px-10 
    no-underline
  `};
  background-image: url(${btnBGImage});
  background-repeat: no-repeat;
  background-position: right -3px;
`;
