import React from 'react';

import imageLogo from '@src/images/limitado/mobile/Intro/marca.svg';
import imageMenu from '@src/images/limitado/mobile/Intro/hamburguer.png';
import imageVideo from '@src/images/limitado/mobile/Intro/video.png';

import ButtonCTA from '@components/ButtonCTA';

import {
    Container,
    Header,
    Menu,
    MenuImage,
    Logo,
    LogoImage,
    Content,
    Headline,
    SubHeadline,
    VideoImage,
    SpanGreen,
} from './styles';

const MobileIntroBlock = () => (
    <Container>
        <Header>
            <Menu>
                <MenuImage src={imageMenu} />
            </Menu>
            <Logo>
                <LogoImage src={imageLogo} />
            </Logo>
        </Header>
        <Content>
            <Headline>Seja um <br />especialista em infraestrutura WEB.</Headline>
            <SubHeadline>
                    Treinamento passo a passo para você aprender administrar servidores Linux 
                    com facilidade utilizando tecnologias de ponta que grandes empresas 
                    como <SpanGreen>Netflix, Ubber e AirBnb</SpanGreen> hospedam seus serviços.
            </SubHeadline>
            <VideoImage src={imageVideo} alt="Vídeo de Vendas" />
        </Content>
        <ButtonCTA href="/checkout">Garantir minha vaga</ButtonCTA>
    </Container>
);

export default MobileIntroBlock;