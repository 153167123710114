import styled from 'styled-components';

import backgroundImage from '@src/images/limitado/mobile/ForWhom/background.png';
import backgroundImageTop from '@src/images/limitado/mobile/ForWhom/background-2.png';
// import courseImage from '@src/images/limitado/desktop/Cta/course-new.png';
import backgroundleft from '@src/images/limitado/desktop/ForWhois/background-left.png';


export const Background = styled.div`
  ${tw`mt-5`};
  background: url(${backgroundleft}) -300px 60px no-repeat, url(${backgroundImageTop}) right top no-repeat;
  background-color: #141318;
`;

export const Container = styled.div`
  /* ${tw`p-8`}; */
`;

export const ContentTitle = styled.div`
  ${tw`text-center pt-32`};
  /* font-size: 38px; */
`;

export const Title = styled.h1`
  ${tw`text-center mt-20 text-4xl font-bold`};
  /* font-size: 38px; */
`;

export const SeparatorLine = styled.img`
    ${tw``};
`;

export const Content = styled.div``;

export const Item = styled.div`
  ${tw`text-center m-10 mb-20`};
`;

export const ItemIcon = styled.img``;

export const ItemText = styled.h2`
  ${tw`mt-5`};
`;

export const ItemDescription = styled.p`
  ${tw`mt-5 text-theme-text-secundary text-lg`};
`;