import styled from 'styled-components';

export const Container = styled.div`
    ${tw`mt-16`};
`;

export const Mockup = styled.img`
    ${tw`w-full`};
`;

export const Content = styled.div`
    ${tw`bg-theme-background-block p-8 text-center`};
`;


export const CountDown = styled.div`
  ${tw`rounded`};
  padding: 20px;
  background-color: #1E1D23;
  /* background-color: #591497; */
`;

export const CountDownTitle = styled.p`
  ${tw``};
  font-size: 18px;
`;

export const Title = styled.h1`
  ${tw`mt-10 font-medium text-3xl`};
`;

export const Subtitle = styled.div`
  ${tw`text-theme-text-purple mt-3`};
  font-size: 25px;
`;

export const Price = styled.div`
  ${tw`font-bold text-theme-text-green flex text-center justify-center items-center items-end`};
  font-size: 95px;
`;

export const PriceSmall = styled.div`
  ${tw`font-bold text-theme-text-green mr-3`};
  font-size: 36px;
  position: relative;
  top: -15px;
`;

export const Description = styled.div`
  ${tw`text-theme-text`};
  font-size: 19px;
`;

export const ButtonContent = styled.div`
  ${tw`mt-16 block mb-16`};
`;

export const ButtonCTA = styled.a`
  ${tw`theme-text-button text-2xl font-medium`};

    background-color: #00D3AE;
    border-bottom: 5px solid #148672;
    border-radius: 30px;
    padding: 20px;
    padding-left: 30px;
    padding-right: 30px;
    color: #11574A;
    text-decoration: none;

    &:hover {
    color: #fff;
    /* border-bottom: 5px solid #11574A; */
    }
`;

export const HotmartContent = styled.div`
  ${tw`mt-16`};
`;

export const HotmartText = styled.div`
  ${tw`text-lg`};
  color:#474655;
`;

export const HotmartIcon = styled.img`
  ${tw`mt-3`};
`;
