import React from 'react';

import icon01Image from '@src/images/limitado/mobile/ForWhom/icon01.png';
import icon02Image from '@src/images/limitado/mobile/ForWhom/icon02.png';
import icon03Image from '@src/images/limitado/mobile/ForWhom/icon03.png';
import icon04Image from '@src/images/limitado/mobile/ForWhom/icon04.png';
import lineImage from '@src/images/limitado/desktop/Faq/line.png';

import {
    Background,
    Container,
    ContentTitle,
    Title,
    SeparatorLine,
    Content,
    Item,
    ItemIcon,
    ItemText,
    ItemDescription,
} from './styles';

const MobileForWhomBlock = () => (
    <Background>
        <Container>
            <ContentTitle>
                <Title>Para quem é?</Title>
                <SeparatorLine src={lineImage} />
            </ContentTitle>
            <Content>
                <Item>
                    <ItemIcon src={icon01Image} alt="Estudantes de TI" />
                    <ItemText>Estudantes de TI</ItemText>
                    <ItemDescription>Seja iniciante ou não</ItemDescription>
                </Item>
                <Item>
                    <ItemIcon src={icon02Image} alt="Interessados em infraestrutura WEB" />
                    <ItemText>Interessados em infraestrutura WEB</ItemText>
                    <ItemDescription>Ou aprender linux em cloud</ItemDescription>
                </Item>
                <Item>
                    <ItemIcon src={icon03Image} alt="Revendedores de hospedagem" />
                    <ItemText>Revendedores de hospedagem</ItemText>
                    <ItemDescription>Que queiram ganhar mais dinheiro</ItemDescription>
                </Item>
                <Item>
                    <ItemIcon src={icon04Image} alt="Donos de sites" />
                    <ItemText>Donos de sites</ItemText>
                    <ItemDescription>Que queiram ter mais autonomia</ItemDescription>
                </Item>
            </Content>
        </Container>
    </Background>
);

export default MobileForWhomBlock;