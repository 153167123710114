import React from 'react';

import lineImage from '@src/images/limitado/desktop/Faq/line.png';
import icon01 from '@src/images/limitado/mobile/AboutTheCourse/icon01.png';
import icon02 from '@src/images/limitado/mobile/AboutTheCourse/icon02.png';
import icon03 from '@src/images/limitado/mobile/AboutTheCourse/icon03.png';
import icon04 from '@src/images/limitado/mobile/AboutTheCourse/icon04.png';

import ButtonCTA from '@src/components/ButtonCTA';

import {
    Container,
    ContentTitle,
    ContentTitleText,
    ContentTitleSeparator,
    Content,
    Item,
    IconImage,
    Title,
    Text,
    ButtonContent,
} from './styles';

const MobileAboutTheCourseBlock = () => (
    <Container>
        <ContentTitle>
            <ContentTitleText>Sobre o Curso</ContentTitleText>
            <ContentTitleSeparator src={lineImage} />
        </ContentTitle>
        <Content>
            <Item>
                <IconImage src={icon01} />
                <Title>Perca o medo do linux</Title>
                <Text>
                    Quer aprender do zero? Na verdade aprender Linux é mais simples do que você imagina, 
                    você vai conhecer e utilizar o shell, aquele terminal que muitos tem medo e ter a segurança 
                    que se seu servidor cair você vai saber o que aconteceu e solucionar. Não deixe seu servidor 
                    e seus códigos na mão de uma outra pessoa.
                </Text>
            </Item>
            <Item>
                <IconImage src={icon02} />
                <Title>Feito por quem entende</Title>
                <Text>
                    Eu fico PUTO com a quantidade de charlatões que têm no mercado de TI, esse foi um dos 
                    fatores que me levou a criar o curso. Trabalho há décadas com programação e já passei 
                    por poucas e boas, vou transferir todo esse conhecimento para você não cometer os 
                    erros que eu cometi quando estava começando e me fizeram perder muito dinheiro.
                </Text>
            </Item>
            <Item>
                <IconImage src={icon03} />
                <Title>Servidor remoto em cloud</Title>
                <Text>
                    Aqui não tem enrolação. Todos os materiais e vídeos pensados para facilitar o dia a dia 
                    de profissionais que trabalham na área de Infraestrutura WEB na Nuvem, AWS, DigitalOcean, 
                    Linode e outros.
                </Text>
            </Item>
            <Item>
                <IconImage src={icon04} />
                <Title>Um suporte gente boa :)</Title>
                <Text>
                    Apesar de ter uma rotina extremamente corrida, vou separar uma parte do meu dia 
                    exclusivamente para atender e tirar dúvidas dos alunos sobre estratégias, materiais do curso. 
                    Só essa espécie de mentoria que você irá receber através do atendimento do whatsapp 
                    já paga o curso mais de 10 vezes.
                </Text>
            </Item>
            <ButtonContent>
                <ButtonCTA href="/checkout">Garantir meu acesso</ButtonCTA>
            </ButtonContent>
        </Content>
    </Container>
);

export default MobileAboutTheCourseBlock;