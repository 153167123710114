import styled from 'styled-components';

import backgroundImage from '@src/images/limitado/desktop/Bonus/background.png';
import titleImage from '@src/images/limitado/desktop/Bonus/background-text.png';
import bonus01 from '@src/images/limitado/desktop/Bonus/image01.png';
import bonus02 from '@src/images/limitado/desktop/Bonus/image02.png';
import bonus03 from '@src/images/limitado/desktop/Bonus/image03.png';
import bonus04 from '@src/images/limitado/desktop/Bonus/image04.png';
import bonus05 from '@src/images/limitado/desktop/Bonus/image05.png';

export const Container = styled.div`
  ${tw`pb-20 pt-20`};
  /* height: 800px; */
  background: url(${backgroundImage}) right bottom no-repeat;
`;

export const ContentTitle = styled.div`
  ${tw`w-full text-center`};
  background: url(${titleImage}) center top no-repeat;
`;

export const ContentTitleText = styled.h1`
  ${tw`font-medium text-5xl mt-10 pt-5`};
`;

export const SeparatorImage = styled.img`

`;

export const ContentPage = styled.div`
  ${tw`w-full mt-20 flex`};
`;

export const Left = styled.div`
  ${tw`w-1/2`};
`;

export const Right = styled.div`
  ${tw`w-1/2`};
`;

export const Bonus01 = styled.div`
  ${tw`mb-10 flex pr-5 rounded`};
  background: url(${bonus01}) left top no-repeat;
  height: 195px;
`;

export const Bonus02 = styled.div`
  ${tw`mb-10 flex pr-5 rounded`};
  background: url(${bonus02}) left top no-repeat;
  height: 195px;
`;

export const Bonus03 = styled.div`
  ${tw`mb-10 flex pr-5 rounded`};
  background: url(${bonus03}) left top no-repeat;
  height: 195px;
`;

export const Bonus04 = styled.div`
  ${tw`mb-10 flex pr-5 rounded`};
  background: url(${bonus04}) left top no-repeat;
  height: 195px;
`;

export const Bonus05 = styled.div`
  ${tw`mb-10 flex pr-5 rounded`};
  background: url(${bonus05}) left top no-repeat;
  height: 195px;
`;

export const ContentLeftBlock = styled.div`
  ${tw`w-1/2`};
`;

export const ContentBonus = styled.div`
  ${tw`bg-theme-background-block w-1/2 rounded p-8`};
  height: 180px;
  margin-top: -25px;
`;


export const BonusType = styled.p`
  ${tw`text-sm leading-normal text-theme-text-purple`};
`;

export const BonusTitle = styled.h1`
  ${tw`font-bold text-2xl leading-normal mt-1 mb-1`};
`;

export const BonusPrice = styled.p`
    ${tw`text-xs leading-normal text-theme-text-third line-through`};
`;

export const ButtonContent = styled.div`
  ${tw`w-full mt-20 pt-10`};
`;