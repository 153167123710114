import styled from 'styled-components';

import backgroundImage from '@src/images/limitado/desktop/ForWhois/background-full.png';
import backgroundleft from '@src/images/limitado/desktop/ForWhois/background-left.png';
import backgroundright from '@src/images/limitado/desktop/ForWhois/background-right.png';
import launchImage from '@src/images/limitado/desktop/ForWhois/launch.svg';

export const Container = styled.div`
  background: url(${launchImage}) 100% bottom no-repeat, url(${backgroundleft}) left bottom no-repeat, url(${backgroundright}) right 170px no-repeat;
  min-height: 850px;
`;

export const ContentPage = styled.div`
  ${tw`flex-col w-full`};
`;

export const ContentTitle = styled.div`
  ${tw`text-2xl font-bold w-full`};
  margin-top: 280px;
`;

export const ContentTitleText = styled.h1`

`;

export const ContentTitleSeparator = styled.img`
  /* ${tw`mb-10`}; */
`;

export const ContentItems = styled.div`
  ${tw`mt-20 flex`};
`;
export const ItemtInitial = styled.div`
  ${tw`flex`};
  width: 400px;
`;

export const ItemSecundary = styled.div`
  ${tw`w-1/2 flex`};
  /* background: yellow */
`;

export const ItemThree = styled.div`
  ${tw`flex`};
  width: 550px;
`;

export const ItemFour = styled.div`
  ${tw`w-1/2 flex`};
  /* background: yellow */
`;

export const IconImage = styled.img`
  ${tw`mr-8`};
  /* width: 70px; */
  height: 84px; 
`;

export const ContentItemsCol = styled.div`
  ${tw`w-full flex`};
`;

export const ContentItemInitial = styled.div`
  ${tw`w-full`};
`;

export const ContentItemInitialTitle = styled.h1`
  ${tw`text-2xl font-medium mt-3 `};
`;

export const ContentItemInitialDescription = styled.p`
  ${tw`text-theme-text leading-normal text-base mt-1`};
`;

