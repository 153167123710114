import styled from 'styled-components';

export const Container = styled.div`
    ${tw`p-8`};
`;

export const Content = styled.div`
    ${tw`flex mb-10 text-center justify-center`};
`;

export const PhoneImage = styled.img`
    ${tw`mr-5`};
`;

export const PhoneContent = styled.div`
    ${tw``};
`;

export const Title = styled.p`
  ${tw`text-xl font-bold`};
`;

export const Text = styled.p`
  ${tw`mt-1`};
`;

export const Link = styled.a`
  ${tw`no-underline text-theme-text`};
`;