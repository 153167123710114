import React, { useEffect, useState } from 'react';

import {
    Container,
    Wrapper,
    TimeSection,
    Time,
    Description,
} from './styles';

const CTACoundDownComponent = () => {
    const [countdownDate, setCountdownDate] = useState(new Date('12/25/2020').getTime());
    const [state, setState] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  
    useEffect(() => {
      setInterval(() => setNewTime(), 1000);
    }, []);
  
    const setNewTime = () => {
      if (countdownDate) {
        const currentTime = new Date().getTime();
  
        const distanceToDate = countdownDate - currentTime;
  
        let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);
  
        const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  
        days = `${days}`;
        if (numbersToAddZeroTo.includes(hours)) {
          hours = `0${hours}`;
        } else if (numbersToAddZeroTo.includes(minutes)) {
          minutes = `0${minutes}`;
        } else if (numbersToAddZeroTo.includes(seconds)) {
          seconds = `0${seconds}`;
        }
  
        setState({ days, hours, minutes, seconds });
      }
    };

    return (
        <Container>
          <Wrapper>
            <TimeSection>
              <Time>{state.days || '0'}</Time>
              <Description>dias</Description>
            </TimeSection>
            <TimeSection>
              <Time>:</Time>
            </TimeSection>
            <TimeSection>
              <Time>{state.hours || '00'}</Time>
              <Description>horas</Description>
            </TimeSection>
            <TimeSection>
              <Time>:</Time>
            </TimeSection>
            <TimeSection>
              <Time>{state.minutes || '00'}</Time>
              <Description>min</Description>
            </TimeSection>
            <TimeSection>
              <Time>:</Time>
            </TimeSection>
            <TimeSection>
              <Time>{state.seconds || '00'}</Time>
              <Description>seg</Description>
            </TimeSection>
          </Wrapper>
        </Container>
      );
}

export default CTACoundDownComponent;