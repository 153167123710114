import styled from 'styled-components';

import faqBackgroundImage from '@src/images/limitado/desktop/Faq/faq-text-background.png';

export const Container = styled.div`
    ${tw`mt-20`};
`;

export const ContentTitle = styled.div`
    ${tw`w-full mt-20 text-center bg-center`};
    background-image: url(${faqBackgroundImage});
    background-repeat: no-repeat;
    font-size: 28px;
    height: 120px;
`;

export const ContentTitleText = styled.h1`
    ${tw`text-5xl font-medium text-white bg-center`};
    padding-top: 40px;
`;

export const SeparatorLine = styled.img`
    ${tw``};
`;

export const Content = styled.div`
    ${tw`w-full flex mt-20`};
`;

export const Separator = styled.div`
    ${tw`w-1/6`};
`;

export const ContentOneColumn = styled.div`
    ${tw`w-5/6 flex`};
`;

export const FaqColumns = styled.div`
    ${tw`flex flex-wrap items-baseline`};
`;

