import styled from 'styled-components';

export const Container = styled.div`
    ${tw``};
`;

export const Header = styled.header`
    ${tw`mt-8 w-full flex`};
`;

export const LogoContent = styled.div`
    ${tw`w-1/6`};
`;

export const LogoContentImage = styled.img`
    ${tw`w-full`};
`;

export const MenuContent = styled.div`
    ${tw`flex-1`};
`;

export const Menu = styled.ul`
  ${tw`list-none flex align-bottom pl-10`};
  margin-top: 15px;
`;

export const MenuItem = styled.li`
  ${tw`flex mr-10 block items-center text-center`};
  
`;

export const MenuItemLink = styled.a`
  ${tw`no-underline text-theme-text text-lg hover:text-white text-base`};
  font-size: 15px;
`;

export const LoginContent = styled.div`
    ${tw`ml-3`};
    width: 160px
`;

