import React from 'react';

import ButtonCTA from '@src/components/ButtonCTA';

import Content from '@src/components/ContentDesktop';
import separatorImage from '@src/images/line.png';

import {
    Container,
    ContentTitle,
    ContentTitleText,
    SeparatorImage,
    ContentPage,
    Left,
    Right,
    Bonus01,
    Bonus02,
    Bonus03,
    Bonus04,
    Bonus05,
    ContentLeftBlock,
    ContentBonus,
    BonusType,
    BonusTitle,
    BonusPrice,
    ButtonContent,
} from './styles';

const DesktopBonusBlock = () => (
    <Container>
        <Content>
            <ContentTitle>
                <ContentTitleText data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">5 bônus exclusivos</ContentTitleText>
                <SeparatorImage src={separatorImage} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" />
            </ContentTitle>
        </Content>
        <Content>
            <ContentPage>
                <Left>
                    <Bonus01>
                        <ContentLeftBlock />
                        <ContentBonus>
                            <BonusType>Minicurso</BonusType>
                            <BonusTitle>Git do Zero ao Avançado</BonusTitle>
                            <BonusPrice>R$ 99,00</BonusPrice>
                        </ContentBonus>
                    </Bonus01>
                    <Bonus02>
                        <ContentLeftBlock />
                        <ContentBonus>
                            <BonusType>Minicurso</BonusType>
                            <BonusTitle>Zabbix & Grafana</BonusTitle>
                            <BonusPrice>R$ 199,00</BonusPrice>
                        </ContentBonus>
                    </Bonus02>
                    <Bonus03>
                        <ContentLeftBlock />
                        <ContentBonus>
                            <BonusType>Minicurso</BonusType>
                            <BonusTitle>Painel de Controle</BonusTitle>
                            <BonusPrice>R$ 99,00</BonusPrice>
                        </ContentBonus>
                    </Bonus03>
                </Left>
                <Right>
                    <Bonus04>
                        <ContentLeftBlock />
                        <ContentBonus>
                            <BonusType>Minicurso</BonusType>
                            <BonusTitle>Configurar e instalar Wordpress</BonusTitle>
                            <BonusPrice>R$ 47,00</BonusPrice>
                        </ContentBonus>
                    </Bonus04>
                    <Bonus05>
                        <ContentLeftBlock />
                        <ContentBonus>
                            <BonusType>Minicurso</BonusType>
                            <BonusTitle>Truque de mestre com Docker</BonusTitle>
                            <BonusPrice>R$ 199,00</BonusPrice>
                        </ContentBonus>
                    </Bonus05>
                    <ButtonContent>
                        <ButtonCTA href="/checkout">Quero os 5 bônus exclusivos</ButtonCTA>
                    </ButtonContent>
                </Right>
            </ContentPage>
        </Content>
    </Container>
);

export default DesktopBonusBlock;