import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Content,
  Avatar,
  Info,
  Title,
  Name,
  Segment,
  Depoiment,
} from './styles';

const DepoimentItemMobileComponent = ({ depoiment }) => {
    
    return (
        <Container>
            <Content>
                {depoiment.node.frontmatter.photo && 
                    <Avatar fluid={depoiment.node.frontmatter.photo.childImageSharp.fluid} alt={depoiment.node.frontmatter.name} />
                }
                <Info>
                    <Title>
                        <Name>{depoiment.node.frontmatter.name}</Name>
                    </Title>
                    <Segment>Aluno do curso</Segment>
                    <Depoiment dangerouslySetInnerHTML={{ __html: depoiment.node.html}} />
                </Info>
            </Content>
        </Container>
    );
}

// DepoimentItemComponent.propTypes = {
//   depoiment: PropTypes.node.isRequired,
// }

export default DepoimentItemMobileComponent;
