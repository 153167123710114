import styled from 'styled-components';

export const Container = styled.div`
    ${tw`p-8 mt-10`};
`;

export const GarantiaImage = styled.img`
  ${tw`w-full`};
`;


export const Content = styled.div`
    ${tw`mt-10`};
`;

export const Title = styled.h1`
    ${tw`w-full text-3xl font-bold`};
`;

export const SeparatorLine = styled.img`
    ${tw``};
`;

export const Text = styled.p`
    ${tw`mt-5 leading-10 text-theme-text`};
    line-height: 1.5rem;
`;

export const Separator = styled.hr`
  ${tw`mt-20`};
  border: 1px solid #484554;
`;