import styled from 'styled-components';

export const Container = styled.div`
  ${tw`justify-center p-10 pt-10 pb-20 text-center`};
`;

export const Title = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #7A7390;
`;

export const Text = styled.p`
  font-size: 12px;
  color: #7A7390;
  margin-top: 15px;
`;

export const Copyright = styled.p`
  font-size: 12px;
  color: #7A7390;
  margin-top: 30px;
`;