import React from 'react';

import certificateImage from '@src/images/limitado/mobile/Certificate/certificate.png';
import lineImage from '@src/images/line.png';

import {
    Container,
    CertificateImage,
    Content,
    Title,
    SeparatorLine,
    Text,
} from './styles';

const MobileCertificateBlock = () => (
    <Container>
        <CertificateImage src={certificateImage} />
        <Content>
            <Title>Certificado</Title>
            <SeparatorLine src={lineImage} />
            <Text>
                Ao finalizar o curso, como reconhecimento do seu empenho, irá poder emitir um certificado 
                nominal que irá comprovar a participação e conclusão do seu curso. Isso vai lhe dar a 
                autoridade necessária para mostrar que você é um profissional proativo, que está sempre 
                em busca de ficar antenado das coisas mais recentes para entregar um trabalho de qualidade. 
                Mas o mais importante mesmo é o sentimento de gratidão e lembrança pelo treinamento que 
                mudou sua vida totalmente. Terão duas pessoas, você antes do curso e depois do curso.
            </Text>
        </Content>
    </Container>
);

export default MobileCertificateBlock;