import React from 'react';

import ContainerDesktop from '@components/ContainerDesktop';

import Intro from './Intro';
import ForWhois from './ForWhois';
import Convince from './Convince';
import AboutTheCourse from './AboutTheCourse';
import Modules from './Modules';
import Technologies from './Technologies';
import Access from './Access';
import Author from './Author';
import Certificate from './Certificate';
import Bonus from './Bonus';
import Cta from './Cta';
import Guarantee from './Guarantee';
import Depoiments from './Depoiments';
import Faq from './Faq';
import FooterContact from './FooterContact';
import FooterMenu from './FooterMenu';
import Footer from './Footer';

import {
    Separator,
} from './styles';

const DesktopPage = () => (
    <ContainerDesktop>
        <Intro />
        <ForWhois />
        <Convince />
        <AboutTheCourse />
        <Modules />
        <Technologies />
        <Access />
        <Author />
        <Certificate />
        <Bonus />
        <Cta />
        <Guarantee />
        <Separator />
        <Depoiments />
        <Faq />
        <FooterContact />
        <FooterMenu />
        <Footer />
    </ContainerDesktop>
);

export default DesktopPage;