import styled from 'styled-components';

import backgroundModules from '@src/images/limitado/desktop/AboutTheCourse/modules.png';
import backgroundButton from '@src/images/limitado/desktop/AboutTheCourse/bg-button1.png';

export const Container = styled.div`
  ${tw`mb-20`};
  margin-top: 140px;
`;


export const Col = styled.div`
  ${tw`flex-1`};
  &:first-child {
    margin-right: 30px;
  }
`;

export const ContentTitle = styled.div`
  ${tw``};
  background: url(${backgroundModules}) 0px 0px no-repeat;
`;

export const Title = styled.h1`
  ${tw`text-5xl font-bold pt-12`};
`;

export const SeparatorLine = styled.img`
  ${tw`mb-20 mt-3`};
`;

export const Item = styled.div`
  ${tw`flex bg-theme-background-block p-8 pb-12 rounded`};
  margin-bottom: 30px;
`;

export const ItemImageContent = styled.div`

`;

export const ItemImage = styled.img`

`;

export const ItemContent = styled.div`
  ${tw`ml-8 mr-5`};
`;

export const ItemTitle = styled.div`
  ${tw`font-medium mb-5 text-2xl mt-5`};
`;

export const ItemDescription = styled.div`
  ${tw`text-theme-text leading-normal text-base`};
  /* font-size: 15px; */
`;

export const ButtonCTAContent = styled.div`
  ${tw`bg-green`};
  background: url(${backgroundButton}) center no-repeat;
  height: 400px;
  padding-top: 150px;
  padding-right: 30px;
`;
