import styled from 'styled-components';

import backgroundImage from '@src/images/limitado/mobile/Convince/path.png';
// import courseImage from '@src/images/limitado/desktop/Cta/course-new.png';

export const Container = styled.div`
  ${tw`bg-white text-theme-text pb-20`};
`;

export const Item = styled.div`
  ${tw`pt-20 p-8`};
`;

export const Image = styled.img`
  ${tw`w-full`};
`;

export const Title = styled.h2`
  ${tw`mt-10 font-medium text-theme-text-secundary`};
`;

export const Text = styled.p`
  ${tw`mt-5 text-theme-text leading-normal`};
  font-size: 15px;
`;

export const ButtonReadContinueContent = styled.div`
  ${tw`flex p-8`};
`;

export const ButtonReadContinue = styled.a`
  ${tw`px-10 py-4 w-3/6 border-solid border-theme-text-purple rounded-full shadow text-theme-text`};
  cursor: pointer;
`;

export const SubContent = styled.div`
  ${tw`-mt-5`};
`;

export const SubContentItem = styled.div`
  ${tw`mt-10 bg-red`};
  background: url(${backgroundImage}) left 0px no-repeat; 
`;

export const SubContentItemText = styled.h3`
  ${tw`pl-8 pr-8 pt-10`};
`;

export const ButtonCTAContent = styled.div`
  ${tw`mt-20`};
`;