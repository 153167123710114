import React, { useState } from 'react';
import PropTypes from 'prop-types';

import iconPlus from '@src/images/icon-plus.png';
import iconMinus from '@src/images/icon-minus.png';


import {
  Container,
  Content,
  ModuleNumberContent,
  ModuleNumberContentText,
  ContentInfo,
  Title,
  Icon,
  Message,
} from './styles';

const ModuleItemMobile = ({ module }) => {
  const [visible, setVisible] = useState(false);

  const handleButtonClick = () => {
    setVisible(!visible);
  }

  return (
    <Container>
      <Content>
        <ModuleNumberContent>
          <ModuleNumberContentText>{module.node.frontmatter.module}</ModuleNumberContentText>
        </ModuleNumberContent>
        {/* <pre>
          <code>{JSON.stringify(module.node.frontmatter.name, null, 2)}</code>
        </pre> */}
          <ContentInfo>
            <Title onClick={handleButtonClick}>{module.node.frontmatter.name}</Title>
            <Icon>
              <a onClick={handleButtonClick}>
                <img src={!visible ? iconPlus : iconMinus} alt="Exibir" />
              </a>
            </Icon>
          </ContentInfo>
          {visible && (
            <Message dangerouslySetInnerHTML={{ __html: module.node.html}} />
          )}
          {!visible && (
            <Message onClick={handleButtonClick}>Clique aqui para ler o resumo do módulo.</Message>
          )}
      </Content>
    </Container>
  );
}

// ModuleItemMobile.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default ModuleItemMobile;
