import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ButtonCTA from '@components/ButtonCTA';
import DepoimentItemComponent from '@components/DepoimentItemMobile';

import lineImage from '@src/images/limitado/desktop/Faq/line.png';

import ScrollHorizontal from 'react-scroll-horizontal';

import {
    Container,
    ContentTitle,
    ContentTitleText,
    SeparatorLine,
    Content,
    Slider,
} from './styles';

const QUERY = graphql`
    query {
        depoiments: allMarkdownRemark(filter: {frontmatter: {type: {eq: "depoiments"}}}) {
            edges {
                node {
                    id
                    html
                    frontmatter {
                        title
                        name
                        photo{
                            childImageSharp{
                                fluid{
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const MobileDepoimentsBlock = () => {
    const depoiments = useStaticQuery(QUERY);
    
    return (
        <Container>
            <ContentTitle>
                <ContentTitleText>Depoimentos dos alunos</ContentTitleText>
                <SeparatorLine src={lineImage} />
            </ContentTitle>
            <Content>
                <Slider>
                    <ScrollHorizontal>
                        {depoiments.depoiments.edges.map(depoiment => <DepoimentItemComponent depoiment={depoiment} key={depoiment.node.id} />)}
                    </ScrollHorizontal>
                </Slider>
            </Content>
            <ButtonCTA href="/checkout" title="Quero ser aluno">Quero ser aluno</ButtonCTA>
        </Container>
    );
}

export default MobileDepoimentsBlock;