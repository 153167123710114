import React from 'react';

import lineImage from '@src/images/line.png';

import image1 from '@src/images/limitado/mobile/Technologies/image1.png';
import image2 from '@src/images/limitado/mobile/Technologies/image2.png';
import image3 from '@src/images/limitado/mobile/Technologies/image3.png';

import {
    Container,
    ContentTitle,
    ContentTitleText,
    ContentTitleSeparator,
    Content,
    IconImage,
    Separator,
} from './styles';

const MobileTechnologiesBlock = () => (
    <Container>
        <ContentTitle>
            <ContentTitleText>Técnologias que você vai aprender</ContentTitleText>
            <ContentTitleSeparator src={lineImage} />
        </ContentTitle>
        <Content>
            <IconImage src={image1} />
            <IconImage src={image2} />
            <IconImage src={image3} />
        </Content>
        <Separator />
    </Container>
);

export default MobileTechnologiesBlock;