import styled from 'styled-components';

export const Container = styled.div`
  ${tw`mb-10 mt-20 p-8`};
`;

export const Title = styled.h1`
    ${tw`text-4xl font-bold text-white`};
`;

export const SeparatorLine = styled.img`
  ${tw`mt-5`};
`;

export const Content = styled.div`
  ${tw`mt-10`};
`;

export const Bonus = styled.div`
    ${tw`bg-theme-background-block mb-10`};
`;

export const BonusImage = styled.img`
    ${tw`w-full`};
`;

export const BonusContent = styled.div`
  ${tw`p-8`};
`;

export const BonusType = styled.p`
  ${tw`text-sm leading-normal text-theme-text-purple`};
`;

export const BonusTitle = styled.h1`
  ${tw`font-bold text-lg leading-normal mt-1 mb-1`};
`;

export const BonusPrice = styled.p`
    ${tw`text-xs leading-normal text-theme-text-third line-through`};
`;


export const ButtonCTAContent = styled.div`
    ${tw`mt-20`};
`;
