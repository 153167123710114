import React from 'react';

import phoneImage from '@src/images/limitado/desktop/FooterMenu/phone.png';
import instagramImage from '@src/images/limitado/desktop/FooterMenu/instagram.png';

import {
    Container,
    ContentPhone,
    ContentSeparator,
    ContentPhoneImage,
    ContentPhoneText,
    PhoneImage,
    ContentInstagram,
    ContentInstagramImage,
    ContentInstagramText,
    InstagramImage,
    Title,
    Text,
    Link,
} from './styles';

const DesktopFooterContactBlock = () => (
    <Container>
        <ContentPhone>
            <ContentSeparator />
            <ContentSeparator />
            <ContentSeparator>
                <ContentPhoneImage>
                    <PhoneImage src={phoneImage} alt="" />
                </ContentPhoneImage>
                <ContentPhoneText>
                    <Title>Telefone</Title>
                    <Text>(37) 3212-8406</Text>
                </ContentPhoneText>
            </ContentSeparator>
        </ContentPhone>
        <ContentInstagram>
            <ContentSeparator>
                <ContentInstagramImage>
                    <InstagramImage src={instagramImage} alt="" />
                </ContentInstagramImage>
                <ContentInstagramText>
                    <Title>Instagram</Title>
                    <Text>
                        <Link href="https://www.instagram.com/marciodias.me" target="_blank">@marciodias.me</Link>
                    </Text>
                </ContentInstagramText>
            </ContentSeparator>
            <ContentSeparator />
            <ContentSeparator />
        </ContentInstagram>
    </Container>
);

export default DesktopFooterContactBlock;