import React from 'react';


import lineImage from '@src/images/line.png';
import dockerImage from '@src/images/limitado/mobile/Bonus/docker.png';
import gitImage from '@src/images/limitado/mobile/Bonus/git.png';
import ispconfigImage from '@src/images/limitado/mobile/Bonus/ispconfig.png';
import wordpressImage from '@src/images/limitado/mobile/Bonus/wordpress.png';
import zabbixImage from '@src/images/limitado/mobile/Bonus/zabbix-grafana.png';

import ButtonCTA from '@components/ButtonCTA';

import {
    Container,
    Title,
    SeparatorLine,
    Content,
    Bonus,
    BonusImage,
    BonusContent,
    BonusType,
    BonusTitle,
    BonusPrice,
    ButtonCTAContent,
} from './styles';

const MobileBonusBlock = () => (
    <Container id="bonus">
        <Title>5 bônus exclusivos</Title>
        <SeparatorLine src={lineImage} />

        <Content>
            <Bonus>
                <BonusImage src={gitImage} />
                <BonusContent>
                    <BonusType>Minicurso</BonusType>
                    <BonusTitle>Git do Zero ao Avançado</BonusTitle>
                    <BonusPrice>R$ 99,00</BonusPrice>
                </BonusContent>
            </Bonus>
            <Bonus>
                <BonusImage src={zabbixImage} />
                <BonusContent>
                    <BonusType>Minicurso</BonusType>
                    <BonusTitle>Zabbix & Grafana</BonusTitle>
                    <BonusPrice>R$ 199,00</BonusPrice>
                </BonusContent>
            </Bonus>
            <Bonus>
                <BonusImage src={ispconfigImage} />
                <BonusContent>
                    <BonusType>Minicurso</BonusType>
                    <BonusTitle>Painel de Controle</BonusTitle>
                    <BonusPrice>R$ 99,00</BonusPrice>
                </BonusContent>
            </Bonus>
            <Bonus>
                <BonusImage src={wordpressImage} />
                <BonusContent>
                    <BonusType>Minicurso</BonusType>
                    <BonusTitle>Configurar e instalar Wordpress</BonusTitle>
                    <BonusPrice>R$ 47,00</BonusPrice>
                </BonusContent>
            </Bonus>
            <Bonus>
                <BonusImage src={dockerImage} />
                <BonusContent>
                    <BonusType>Minicurso</BonusType>
                    <BonusTitle>Truque de mestre com Docker</BonusTitle>
                    <BonusPrice>R$ 199,00</BonusPrice>
                </BonusContent>
            </Bonus>
        </Content>

        <ButtonCTAContent>
            <ButtonCTA href="/checkout">Quero os 5 bônus exclusivos</ButtonCTA>
        </ButtonCTAContent>
    </Container>
);

export default MobileBonusBlock;