import styled from 'styled-components';

import backgroundTOPImage from '@src/images/limitado/mobile/Intro/background-top.png';

export const Container = styled.div`
  ${tw`p-8`};
  background: url(${backgroundTOPImage}) right top no-repeat;
`;

export const Header = styled.div`
    ${tw`flex`};
`;

export const Menu = styled.div`
    ${tw`w-1/6`};
`;

export const MenuImage = styled.img`

`;

export const Logo = styled.div`
    ${tw`flex-auto text-right`};
`;

export const LogoImage = styled.img`

`;

export const Content = styled.div`
    ${tw`mt-10 mb-16`};
`;

export const Headline = styled.h1`
    ${tw`text-4xl font-bold`};
`;

export const SubHeadline = styled.h3`
    ${tw`text-lg text-theme-text mt-5`};
`;

export const SpanGreen = styled.span`
    ${tw`text-theme-text-green`};
`;

export const VideoImage = styled.img`
    ${tw`w-full mt-10`};
`;