import styled from 'styled-components';

import backgroundImage from '@src/images/limitado/desktop/Intro/background-top-right.png';

export const Container = styled.div`
    background: url(${backgroundImage}) right top no-repeat;
`;

export const Left = styled.div`
    ${tw`w-full mt-20`};
    width: 600px;
`;

export const ButtonContent = styled.div`
    ${tw`flex mt-16`};
`;

export const Headline = styled.h1`
    ${tw`text-5xl font-bold shadow`};
`;

export const SpanGreen = styled.span`
    ${tw`text-theme-text-green`};
`;

export const SubHeadline = styled.h2`
    ${tw`text-base mt-5 leading-normal text-theme-text-secundary`};
    color: #6F6A80;
`;

export const Right = styled.div`
    ${tw`w-full pl-16`};
`;

export const Video = styled.img`
    ${tw`w-full mt-20 rounded shadow-xl`};
`;