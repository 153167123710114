import styled from 'styled-components';

export const Container = styled.div`
    ${tw``};
`;

export const ContentTitle = styled.div`
    ${tw`w-full p-8 text-center bg-center text-2xl font-bold`};
`;

export const ContentTitleText = styled.h1`
    ${tw`text-6xl font-medium text-white bg-center`};
`;

export const SeparatorLine = styled.img`
    ${tw``};
`;

export const Content = styled.div`
    ${tw`flex mb-20`};
`;

export const Slider = styled.div`
    ${tw``};
    height: 215px;
    width: 100%;
`;