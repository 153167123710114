import React from 'react';

import Header from '../Header';
import Content from '@src/components/ContentDesktop';

import videoImage from '@src/images/limitado/desktop/Intro/video.png';

import ButtonCTA from '@src/components/ButtonCTA';

import {
    Container,
    Left,
    Headline,
    SubHeadline,
    ButtonContent,
    Right,
    Video,
    SpanGreen,
} from './styles';

const DesktopIntroBlock = () => (
    <Container>
        <Header />
        <Content>
            <Left>
                <Headline>
                    Seja um especialista em infraestrutura web.
                </Headline>
                <SubHeadline>
                    Treinamento passo a passo para você aprender administrar servidores Linux 
                    com facilidade utilizando tecnologias de ponta que grandes empresas 
                    como <SpanGreen>Netflix, Ubber e AirBnb</SpanGreen> hospedam seus serviços.
                </SubHeadline>
                <ButtonContent>
                    <ButtonCTA href="/checkout">Garantir minha vaga</ButtonCTA>
                </ButtonContent>
            </Left>
            <Right>
                <Video src={videoImage} alt="Inscrições abertas" data-sal="fade" data-sal-delay="200" data-sal-easing="ease" />
            </Right>
        </Content>
    </Container>
);

export default DesktopIntroBlock;