import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
    ${tw`mt-4 flex`};
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TimeSection = styled.div`
  ${tw`text-theme-text-purple`};
  padding: 0px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Time = styled.p`
  ${tw`text-theme-text-purple font-medium text-3xl`};
`;

export const Description = styled.small`
  ${tw`text-theme-text-purple`};
  font-size: 16px;
`;