import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import FaqItemComponent from '@components/FaqItem';

import lineImage from '@src/images/limitado/desktop/Faq/line.png';

import {
    Container,
    ContentTitle,
    ContentTitleText,
    SeparatorLine,
    Content,
    Separator,
    ContentOneColumn,
    FaqColumns,
    OneColumn,
} from './styles';

const QUERY = graphql`
    query {
        faqs: allMarkdownRemark(filter: {frontmatter: {type: {eq: "faq"}}}) {
            edges {
                node {
                    frontmatter {
                        title
                    }
                    html
                }
            }
        }
    }  
`;
  

const DesktopFaqBlock = () => {
    const faqs = useStaticQuery(QUERY);

    return (
    <Container>
        <ContentTitle>
            <ContentTitleText data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease">Alguma dúvida ?</ContentTitleText>
            <SeparatorLine src={lineImage} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" />
        </ContentTitle>
        <Content>
            <Separator />
            <ContentOneColumn>
                <FaqColumns>
                    {faqs.faqs.edges.map(faq => <FaqItemComponent faq={faq} key={faq.node.frontmatter.title} /> )}
                </FaqColumns>
            </ContentOneColumn>
            <Separator />
        </Content>
    </Container>
    );
}

export default DesktopFaqBlock;