import React, { useState } from 'react';
import PropTypes from 'prop-types';

import iconPlus from '@src/images/icon-plus.png';
import iconMinus from '@src/images/icon-minus.png';


import {
  Container,
  Content,
  ModuleNumber,
  ModuleNumberText,
  ModuleContent,
  Title,
  Icon,
  IconLink,
  Message,
} from './styles';

const ModuleItemMobile = ({ module }) => {
  const [visible, setVisible] = useState(false);

  const handleButtonClick = () => {
    setVisible(!visible);
  }

  return (
    <Container>
      <Content>
        <ModuleNumber>
          <ModuleNumberText>{module.node.frontmatter.module}</ModuleNumberText>
        </ModuleNumber>
        <ModuleContent>
          <Title>{module.node.frontmatter.name}</Title>
          {visible && (
            <Message dangerouslySetInnerHTML={{ __html: module.node.html}} />
          )}
          {!visible && (
            <Message>Clique aqui para ler o resumo do módulo.</Message>
          )}
        </ModuleContent>
        <Icon>
            <IconLink onClick={handleButtonClick}>
              <img src={!visible ? iconPlus : iconMinus} alt="Exibir" />
            </IconLink>
        </Icon>
      </Content>
    </Container>
  );
}

// ModuleItemMobile.propTypes = {
//   module: PropTypes.node.isRequired,
// }

export default ModuleItemMobile;
