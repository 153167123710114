import styled from 'styled-components';
import Img from 'gatsby-image';

export const Container = styled.div`
    ${tw`bg-theme-background-block ml-5`};
    width: 360px;
    height: 215px;
    transition: transform 300ms ease 100ms;

    /* &:hover {
        transform: translateX(-25%);
        transform: scale(1.5) !important; 
    } */
`;

export const Content = styled.div`
    ${tw`flex items-start px-4 py-6`};
`;


export const Avatar = styled(Img)`
    ${tw`w-12 h-12 rounded-full object-cover mr-5 shadow`};
    width: 220px;
    /* border: 1px solid #fafafa; */
`;

export const Info = styled.div`
    ${tw``};
`;

export const Title = styled.div`
    ${tw`flex items-center justify-between`};
`;

export const Name = styled.h2`
    ${tw`text-lg font-semibold text-gray-900 -mt-1`};
`;

export const Segment = styled.p`
    ${tw`text-gray-700`};
`;
export const Depoiment = styled.div`
    ${tw`mt-3 text-gray-700 text-sm`};
`;

