import React from 'react';

import {
    Container,
    Title,
    Text,
    Copyright,
} from './styles';

const DesktopFooterMobileBlock = () => (
    <Container>
        <Title>MÁRCIO DIAS - SOLUÇÕES DIGITAIS</Title>
        <Text>Rua Monte Santo 551 sala 03 - Santo Antônio - Divinópolis / Minas Gerais / Brasil</Text> 
        <Text>CNPJ: 23.269.415/0001-60</Text>
        <Copyright>© Copyright {new Date().getFullYear()} - Todos os Direitos Reservados</Copyright>
    </Container>
);

export default DesktopFooterMobileBlock;