import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import separatorImage from '@src/images/line.png';
import bonusImage from '@src/images/limitado/desktop/Modules/icon-bonus.png';
import nextImage from '@src/images/limitado/desktop/Modules/next.png';

import Content from '@src/components/ContentDesktop';
import ModuleItem from '@src/components/ModuleItemDesktop';

import {
    Container,
    ContentDefault,
    ContentTitle,
    Title,
    SeparatorImage,
    ContentDescription,
    Col,
    ContentDescriptionTitle,
    ContentDescriptionText,
    ContentDescriptionSpan,
    ContentItem,
    ContentBonus,
    BonusIcon,
    ContentBonusContent,
    BonusIconImage,
    BonusContent,
    BonusContentTitle,
    BonusContentMessage,
    ContentNextIcon,
    ContentNextIconImage,
} from './styles';

const QUERY = graphql`
    query {
        modules: allMarkdownRemark(sort: {fields: frontmatter___module, order: ASC}, filter: {frontmatter: {type: {eq: "modules"}}}) {
            edges {
                node {
                    id
                    html
                    frontmatter {
                        module
                        name
                    }
                }
            }
        }
    }
`;  


const DesktopModulesBlock = () => {
    const modules = useStaticQuery(QUERY);

    return(
        <Container>
            <Content>
                <ContentDefault>
                    <ContentTitle>
                        <Title data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease">Conteúdo do curso</Title>
                        <SeparatorImage src={separatorImage} alt="Conteúdo do curso" data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" />
                    </ContentTitle>
                    <ContentDescription>
                        <Col>
                            <ContentDescriptionTitle>sua jornada <br /> começa aqui<ContentDescriptionSpan>.</ContentDescriptionSpan></ContentDescriptionTitle>
                        </Col>
                        <Col>
                            <ContentDescriptionText>
                                Comece sua jornada bootcamp e seja um especialista em infraestrutura web, 
                                Você vai aprender a instalar e configurar um servidor cloud do zero utilizando o 
                                sistema operacional Linux, vai dominar as configuracoes do Nginx, Apache, Proxy, SSL, 
                                Banco de dados, Cron, monitoramento, deploy, git, docker, zabbix, de fato você vai 
                                dominar e administrar um servidor 100% ilimitado e totalmente seu, com certeza vai abandonar 
                                de vez sua hospedagem compartilhada e limitada.
                            </ContentDescriptionText>
                        </Col>
                    </ContentDescription>
                </ContentDefault>
            </Content>
            <Content>
                <ContentItem>
                    {modules.modules.edges.map(module => <ModuleItem module={module} key={module.node.id} />)}
                    <ContentBonus>
                        <ContentBonusContent>
                            <BonusIcon>
                                <BonusIconImage src={bonusImage} alt="Bônus" />
                            </BonusIcon>
                            <BonusContent>
                                <BonusContentTitle>Bônus exclusivos</BonusContentTitle>
                                <BonusContentMessage>Clique aqui para ir à seção dos bônus.</BonusContentMessage>
                            </BonusContent>
                        </ContentBonusContent>
                    </ContentBonus>
                </ContentItem>
            </Content>
            <Content>
                <ContentNextIcon>
                    <ContentNextIconImage src={nextImage} />
                </ContentNextIcon>
            </Content>
        </Container>
    );
};

export default DesktopModulesBlock;