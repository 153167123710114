import styled from 'styled-components';

import faqBackgroundImage from '@src/images/limitado/desktop/Faq/faq-text-background.png';


export const Container = styled.div`
    ${tw`p-8`};
`;

export const ContentTitle = styled.div`
    ${tw`w-full mt-20 text-center bg-center text-2xl font-bold`};
    background-image: url(${faqBackgroundImage});
    background-repeat: no-repeat;
`;

export const ContentTitleText = styled.h1`
    ${tw`text-6xl font-medium text-white bg-center`};
`;

export const SeparatorLine = styled.img`
    ${tw``};
`;

export const Content = styled.div`
    ${tw`w-full flex mt-20`};
`;

export const Faq = styled.div`
    ${tw``};
`;
