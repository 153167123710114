import React from 'react';

import imageLogo from '@src/images/marca.svg';
import userIcon from '@src/images/limitado/mobile/FooterMenu/user-icon.png';

import ButtonLogin from '@src/components/ButtonLogin';

import {
    Container,
    Content,
    Content1,
    Logo,
    ContentFullWidth,
    ButtonContent,
} from './styles';

const MobileFooterMenuBlock = () => (
    <Container>
        <Content1>
            <Logo src={imageLogo} />
        </Content1>
        <Content>
            <ContentFullWidth>
                <ButtonContent>
                    <ButtonLogin href="/curso" target="_blank">
                        Área do aluno
                    </ButtonLogin>
                </ButtonContent>
            </ContentFullWidth>
        </Content>
    </Container>
);

export default MobileFooterMenuBlock;