import styled from 'styled-components';

export const Container = styled.div`
  ${tw`p-8 mt-10`};
`;

export const ContentTitle = styled.div`
  ${tw``};
`;

export const ContentTitleText = styled.h1`
  ${tw`text-4xl`};
`;

export const ContentTitleSeparator = styled.img`
  ${tw``};
`;

export const ContentTitleMessage = styled.div`
  ${tw`mt-5 text-theme-text leading-normal`};
  /* font-size: 15px; */
`;

export const Content = styled.div`
  ${tw`mt-20`};
`;

export const Bonus = styled.div`
  ${tw`bg-theme-background-block p-5 pb-8 rounded`};
`;

export const BonusImage = styled.img`
  margin-top: -50px;
`;

export const BonusText = styled.div`
    ${tw`mt-3 font-bold text-white content-center`};
    font-size: 17px;
`;

export const BonusMessage = styled.a`
    ${tw`mt-10 text-theme-text text-base leading-normal no-underline`};
    line-height: 1.5rem;
`;

export const IconImageContent = styled.div`
  ${tw`text-center items-center block`};
  position: relative;
  top: 60px;
`;

export const LinkImageNext = styled.a`
  cursor: pointer;
`;

export const IconImageNext = styled.img`
  /* position: relative; */
`;