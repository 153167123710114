import React from 'react';

import Content from '@components/ContentDesktop';
import ButtonCTA from '@components/ButtonCTA';

import image01 from '@src/images/limitado/desktop/Access/image-left.png';
import separatorLineImage from '@src/images/line.png';

import {
    Container,
    ItemContent,
    Item,
    Left,
    Right,
    Image,
    Title,
    SeparatorLine,
    Text,
    ButtonCTAContent,
} from './styles';

const AccessDesktopPage = () => {
    return  (
        <Container>
            <Content>
                <ItemContent>
                    <Item>
                        <Left>
                            <Image src={image01} alt="Situação 01" />
                        </Left>
                        <Right>
                            <Title>Seu acesso</Title>
                            <SeparatorLine src={separatorLineImage} />
                            <Text>
                                Poucos instantes depois que você efetuar o pagamento (boleto pode levar até 2 dias úteis 
                                para compensar) você irá receber seu acesso único e exclusivo no seu e-mail pessoal. 
                                Podendo já matar a curiosidade do conteúdo e aprender para colocar em prática.
                            </Text>
                            <Text>
                                O melhor é que o curso é totalmente online. Você irá economizar com deslocamento e outras 
                                coisas caso fosse um curso presencial. E a cereja do bolo, poderá assistir quantas vezes 
                                quiser as aulas com acesso vitalício, sem se preocupar com qualquer limite.
                            </Text>
                            <ButtonCTAContent>
                                <ButtonCTA href="/checkout">Garantir meu acesso</ButtonCTA>
                            </ButtonCTAContent>
                        </Right>
                    </Item>
                </ItemContent>
            </Content>
        </Container>
    );
};

export default AccessDesktopPage;