import styled from 'styled-components';

import backgroundImage from '@src/images/limitado/desktop/Bonus/background.png';
import pathContentImage from '@src/images/limitado/mobile/Convince/path.png';

export const Container = styled.div`
  ${tw`bg-white pt-20 pb-20`};
  margin-top: -5px;
`;

export const ItemContent = styled.div`
  ${tw``};
`;

export const Item = styled.div`
  ${tw`pt-20 p-8 flex mb-20`};
`;

export const Left = styled.div`
  ${tw`w-1/2 mr-10`};
`;

export const Right = styled.div`
  ${tw`w-1/2 ml-10 pt-5`};
`;

export const Image = styled.img`
  ${tw`w-full`};
`;

export const Title = styled.h2`
  ${tw`font-medium text-theme-text-secundary mb-10`};
`;

export const Text = styled.p`
  ${tw`mt-5 text-theme-text leading-normal text-base`};
`;

export const ButtonReadContinueContent = styled.div`
  ${tw`flex mt-20`};
`;

export const ButtonReadContinue = styled.a`
  ${tw`px-10 py-4 w-3/6 border-solid border-theme-text-purple rounded-full shadow text-theme-text`};
  cursor: pointer;
`;

export const SubReadContent = styled.div`
  ${tw`w-full`};
  background: url(${pathContentImage}) left 0px no-repeat; 
`;

export const SubReadContentText = styled.p`
  ${tw`font-medium text-theme-text-four text-xl p-8 leading-normal`};
  color: #323136;
`;

export const SubReadContentTextColor = styled.span`
  ${tw`text-theme-text-green2 leading-normal`}
  color: ##41D999;
  text-decoration: underline solid #41D999;
`;

export const ButtonCTAContent = styled.div`
  margin-top: 100px;
  ${tw`mb-10`};
`;