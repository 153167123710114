import React from 'react';

import separatorImage from '@src/images/line.png';
import icon01 from '@src/images/limitado/desktop/Technologies/icon1.png';
import icon02 from '@src/images/limitado/desktop/Technologies/icon2.png';
import icon03 from '@src/images/limitado/desktop/Technologies/icon3.png';
import icon04 from '@src/images/limitado/desktop/Technologies/icon4.png';
import icon05 from '@src/images/limitado/desktop/Technologies/icon5.png';
import icon06 from '@src/images/limitado/desktop/Technologies/icon6.png';
import icon07 from '@src/images/limitado/desktop/Technologies/icon7.png';
import icon08 from '@src/images/limitado/desktop/Technologies/icon8.png';
import icon09 from '@src/images/limitado/desktop/Technologies/icon9.png';
import icon10 from '@src/images/limitado/desktop/Technologies/icon10.png';
import icon11 from '@src/images/limitado/desktop/Technologies/icon11.png';
import icon12 from '@src/images/limitado/desktop/Technologies/icon12.png';
import icon13 from '@src/images/limitado/desktop/Technologies/icon13.png';

import Content from '@src/components/ContentDesktop';

import {
    Container,
    ContentTitle,
    Title,
    SeparatorImage,
    ContentTitleLeft,
    ContentTitleRight,
    Hr,
    ContentIcons,
    Icon,
    ContentSeparator,
} from './styles';

const TechnologiesDesktopPage = () => (
    <Container>
        <Content>
            <ContentTitle>
                <ContentTitleLeft>
                    <Title>Tecnologias que você vai aprender</Title>
                    <SeparatorImage src={separatorImage} alt="Conteúdo do curso" />
                </ContentTitleLeft>
                <ContentTitleRight>
                    <Hr />
                </ContentTitleRight>
            </ContentTitle>
        </Content>
        <Content>
            <ContentIcons>
                <Icon src={icon01} />
                <Icon src={icon02} />
                <Icon src={icon03} />
                <Icon src={icon04} />
                <Icon src={icon05} />
                <Icon src={icon06} />
                <Icon src={icon07} />
            </ContentIcons>
        </Content>
        <Content>
            <ContentIcons>
                <Icon src={icon08} />
                <Icon src={icon09} />
                <Icon src={icon10} />
                <Icon src={icon11} />
                <Icon src={icon12} />
                <Icon src={icon13} />
            </ContentIcons>
        </Content>
        <Content>
            <ContentSeparator>
                <Hr />
            </ContentSeparator>
        </Content>
    </Container>
);

export default TechnologiesDesktopPage;