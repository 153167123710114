import styled from 'styled-components';

import backgroundImage from '@src/images/limitado/desktop/Bonus/background.png';
import pathContentImage from '@src/images/limitado/mobile/Convince/path.png';
import backgroundAccess from '@src/images/limitado/desktop/Access/background-access.png';

export const Container = styled.div`
  ${tw`bg-white pb-20`};
`;

export const ItemContent = styled.div`
  ${tw``};
`;

export const Item = styled.div`
  ${tw`pt-20 p-8 flex mb-20`};
`;

export const Left = styled.div`
  ${tw`w-1/2 mr-10`};
`;

export const Right = styled.div`
  ${tw`w-1/2 ml-10 pt-5`};
  background: url(${backgroundAccess}) top left no-repeat;
`;

export const Image = styled.img`
  ${tw`w-full`};
`;

export const Title = styled.h2`
  ${tw`font-medium text-theme-text-secundary text-5xl`};
`;


export const SeparatorLine = styled.img`
  ${tw`mb-5 mt-3`};
`;

export const Text = styled.p`
  ${tw`mt-5 text-theme-text leading-normal text-base`};
  font-size: 15px;
`;

export const ButtonCTAContent = styled.div`
    ${tw`w-full mt-16 flex`};
`;