import styled from 'styled-components';

import backgroundLeftImage from '@src/images/limitado/desktop/Certificate/background-left.png';

export const Container = styled.div``;

export const ContentColor = styled.div`
    ${tw`bg-theme-background-block flex w-full rounded`};
    margin-top: -10px;
    height: 450px;
`;

export const Left = styled.div`
    ${tw`w-1/2`};
    background: url(${backgroundLeftImage}) left top no-repeat;
`;

export const CertificateImage = styled.img`
    margin-left: -100px;
    margin-top: 40px;
`;

export const Right = styled.div`
    ${tw`w-1/2`};
`;

export const Title = styled.h1`
    ${tw`text-5xl font-bold mt-16 text-white`};
`;

export const SeparatorLine = styled.img`

`;

export const Text = styled.p`
  ${tw`mt-5 text-theme-text leading-normal text-base mr-20`};
  margin-right: 100px;
`;