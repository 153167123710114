import styled from 'styled-components';

export const Container = styled.div`
  ${tw`bg-theme-background-block pb-20`};
`;

export const CertificateImage = styled.img`
  ${tw`w-full`};
  margin-top: -60px;
`;

export const Content = styled.div`
  ${tw`p-8 -mt-20`};
`;

export const Title = styled.div`
  ${tw`text-4xl font-bold text-white`};
`;

export const SeparatorLine = styled.img`
  ${tw`mt-5`};
`;

export const Text = styled.div`
    ${tw`mt-10 text-base font-medium text-theme-text-secundary leading-normal`};
`;
