import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import lineImage from '@src/images/line.png';
import iconBonusImage from '@src/images/limitado/mobile/Modules/bonus.png';
import iconDownImage from '@src/images/next.png';

import ModuleItemMobile from '@components/ModuleItemMobile';

import {
    Container,
    ContentTitle,
    ContentTitleText,
    ContentTitleSeparator,
    ContentTitleMessage,
    Content,
    Bonus,
    BonusImage,
    BonusText,
    BonusMessage,
    IconImageContent,
    IconImageNext,
    LinkImageNext,
} from './styles';


const QUERY = graphql`
    query {
        modules: allMarkdownRemark(sort: {fields: frontmatter___module, order: ASC}, filter: {frontmatter: {type: {eq: "modules"}}}) {
            edges {
                node {
                    id
                    html
                    frontmatter {
                        module
                        name
                    }
                }
            }
        }
    }
`;  

const MobileModulesBlock = () => {
    const modules = useStaticQuery(QUERY);

    return(
        <Container>
            <ContentTitle>
                <ContentTitleText>Conteúdo do curso</ContentTitleText>
                <ContentTitleSeparator src={lineImage} />
                <ContentTitleMessage>
                    Comece sua jornada bootcamp e seja um especialista em infraestrutura web, 
                    Você vai aprender a instalar e configurar um servidor cloud do zero utilizando o 
                    sistema operacional Linux, vai dominar as configuracoes do Nginx, Apache, Proxy, SSL, 
                    Banco de dados, Cron, monitoramento, deploy, git, docker, zabbix, de fato você vai dominar 
                    e administrar um servidor 100% ilimitado e totalmente seu, com certeza vai abandonar de 
                    vez sua hospedagem compartilhada e limitada.
                </ContentTitleMessage>
            </ContentTitle>
            <Content>
                {modules.modules.edges.map(module => <ModuleItemMobile module={module} key={module.node.id} />)}
            </Content>
            <Bonus>
                <BonusImage src={iconBonusImage} alt="Bônus" />
                <BonusText>5 Bônus exclusivos</BonusText>
                <BonusMessage href="#bonus">Clique aqui para ir à seção de bônus.</BonusMessage>
            </Bonus>
            <IconImageContent>
                <LinkImageNext href="#bonus">
                    <IconImageNext src={iconDownImage} />
                </LinkImageNext>
            </IconImageContent>
        </Container>
    );
}

export default MobileModulesBlock;