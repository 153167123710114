import styled from 'styled-components';

export const Container = styled.div`
  ${tw`pt-20 bg-white pb-20`};
`;


export const ContentTitle = styled.div`
  ${tw`w-full flex mt-20`};
`;

export const ContentTitleLeft = styled.div`
  ${tw`w-1/2`};
`;

export const ContentTitleRight = styled.div`
  ${tw`w-1/2 pt-5`};
  margin-left: -50px;
`;


export const Title = styled.h1`
  ${tw`text-3xl text-black`};
`;

export const SeparatorImage = styled.img`
  /* ${tw`text-3xl text-black`}; */
`;

export const Hr = styled.hr`
  /* ${tw`text-3xl text-black`}; */
  color: #f4f4f4;
  border: 1px solid #f4f4f4;
`;

export const ContentIcons = styled.div`
  ${tw`w-full mt-20`};
`;

export const Icon = styled.img`
  ${tw`mr-10`};
`;

export const ContentSeparator = styled.div`
  ${tw`w-full mt-20`}
`;