import React, { useState } from 'react';
import PropTypes from 'prop-types';

import iconPlus from '@src/images/icon-plus.png';
import iconMinus from '@src/images/icon-minus.png';

import {
  Container,
  Content,
  Title,
  Icon,
  Message,
} from './styles';

const FaqItemMobileComponent = ({ faq }) => {
  const [visible, setVisible] = useState(false);

  const handleButtonClick = () => {
    setVisible(!visible);
  }

  return (
    <Container>
        <Content>
          <Title>{faq.node.frontmatter.title}</Title>
          <Icon>
            <a onClick={handleButtonClick}>
                <img src={!visible ? iconPlus : iconMinus} alt="Exibir" />
            </a>
          </Icon>
        </Content>

        {visible && (
          <Message dangerouslySetInnerHTML={{ __html: faq.node.html}} />
        )}
    </Container>
  );
}

// FaqItemComponent.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default FaqItemMobileComponent;
