import React from 'react';

import backgroundLeftImage from '@src/images/limitado/desktop/Garantia/background-left@2x.png';

import lineImage from '@src/images/limitado/desktop/Faq/line.png';


import {
    Container,
    Separator,
    Content,
    GridLeft,
    GarantiaImage,
    GridRight,
    Title,
    SeparatorLine,
    Paragraph,
} from './styles';

const DesktopGuaranteeBlock = () => (
    <Container>
        <Separator />
        <Content>
            <GridLeft>
                <GarantiaImage src={backgroundLeftImage} data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease" />
            </GridLeft>
            <GridRight>
                <Title data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease">Garantia de 7 dias</Title>
                <SeparatorLine src={lineImage} alt="Garantia de 7 dias" data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" />
                <Paragraph>Eu estou entregando tanto conteúdo de corpo de alma nesse curso que ofereço uma garantia incondicional. Se você não gostar do curso por qualquer motivo, com um simples e-mail você recebe o estorno integral do seu curso sem nenhuma pergunta e sem nenhum ressentimento. O risco por você comprar é todo meu. Você compra e só depois decide.</Paragraph>
                <Paragraph>Você tem 7 dias corridos de garantia e a qualquer momento pode pedir seu investimento de volta. A última coisa que quero é ficar com seu investimento e você ficar infeliz. Este é o nosso acordo.</Paragraph>
            </GridRight>
        </Content>
        <Separator />
    </Container>
);

export default DesktopGuaranteeBlock;