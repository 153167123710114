import React from 'react';

import CTACoundDownMobileComponent from '@components/CTACountDownMobile';

import mockupImage from '@src/images/limitado/mobile/Cta/mockup.png';
import hotmartIcon from '@src/images/limitado/desktop/Cta/hotmart-icon.png';

import {
    Container,
    Mockup,
    Content,
    CountDown,
    CountDownTitle,
    Title,
    Subtitle,
    Price,
    PriceSmall,
    Description,
    ButtonContent,
    ButtonCTA,
    HotmartContent,
    HotmartText,
    HotmartIcon,
} from './styles';

const MobileCtaBlock = () => (
    <Container>
        <Mockup src={mockupImage} />
        <Content>

            <CountDown>
                <CountDownTitle>Essa oferta se encerra em</CountDownTitle>
                <CTACoundDownMobileComponent />
            </CountDown>
            
            <Title>Domine a infraestrutura WEB em servidores VPS</Title>
            <Subtitle>por apenas:</Subtitle>
            <Price>
                <PriceSmall>R$</PriceSmall> 297
            </Price>
            <Description>
                Ou em até 10x de R$ 29,70.
            </Description>
            <ButtonContent>
                <ButtonCTA href="/checkout" target="_blank">
                    Fazer inscrição agora
                </ButtonCTA>
            </ButtonContent>
            <HotmartContent>
                <HotmartText>Compra segura via</HotmartText>
                <HotmartIcon src={hotmartIcon} alt="Hotmart" />
            </HotmartContent>
        </Content>
    </Container>
);

export default MobileCtaBlock;