import styled from 'styled-components';

export const Container = styled.div`
  ${tw`bg-white p-8 pb-20`};
`;

export const IconImage = styled.img`
  ${tw`w-full`};
`;

export const Content = styled.div`
  ${tw`mt-20`};
`;

export const Title = styled.h1`
  ${tw`text-3xl`};
  color: grey;
`;

export const Separator = styled.img`
  ${tw``};
`;

export const Text = styled.p`
  ${tw`mt-10 text-base font-medium text-theme-text-secundary leading-normal`};
`;

export const ButtonContent = styled.div`
  ${tw`mt-20 flex`};
`;
