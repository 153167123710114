import React, { useState } from 'react';

import ButtonCTA from '@src/components/ButtonCTA';

import image01 from '@src/images/limitado/mobile/Convince/01.png';
import image02 from '@src/images/limitado/mobile/Convince/02.png';
import image03 from '@src/images/limitado/mobile/Convince/03.png';

import {
    Container,
    Item,
    Image,
    Title,
    Text,
    ButtonReadContinueContent,
    ButtonReadContinue,
    SubContent,
    SubContentItem,
    ButtonCTAContent,
    SubContentItemText,
} from './styles';

const MobileConvinceBlock = () => {
    const [subContentVisible, setSubContentVisible] = useState(false);

    const handleOpenSubcontent = () => {
        setSubContentVisible(!subContentVisible);
    }

    return (
        <Container>
            <Item>
                <Image src={image01} alt="Situação 01" />
                {/* <Title>Você está trazendo uma faca de mesa pra um tiroteiro?</Title> */}
                <Title>Embarque nesse foguete e comece a avançar agora mesmo na direção dos seus objetivos.</Title>
                <Text>
                    O mercado de TI está cada dia mais prostituído, a maioria dos profissionais não 
                    são valorizados. Mas tem algo interessante, há pouquíssimas pessoas ganhando 
                    MUITO dinheiro com essa área.
                </Text>
                <Text>Com esse curso você terá o passo a passo prático para desvendar o mapa do tesouro.</Text>
                <Text>Está cansado de se sentir estagnado, de desconfiar que seus concorrentes sabem mais do que você?</Text>
                <Text>
                    Infelizmente é verdade, você sabe, as pessoas adoram reclamar, e estão sendo "escondendo o ouro", 
                    tem muito concorrente seu se fazendo de bobo e enchendo os bolsos de dinheiro.
                </Text>
            </Item>
            <Item>
                <Image src={image02} alt="Situação 02" />
                <Title>Eu acredito que você quer se sentir realizado com o que faz.</Title>
                <Text>
                    E o melhor, ganhar muito bem! Podendo contratar funcionários quando estiver maior e fazendo 
                    sua empresa funcionar no piloto automático, com você apenas monitorando os funcionários, possibilitando 
                    curtir mais a vida com sua família, nosso maior tesouro.
                </Text>
                <Text>
                    E por fim, vai aprender além de tudo, a ganhar confiança, perder aquele medo de fazer algo errado e 
                    colocar tudo a perder que você fez em dias (que podem ser semanas, meses ou até mesmo anos dependendo 
                    do seu projeto).
                </Text>
            </Item>
            <Item>
                <Image src={image03} alt="Situação 03" />
                <Title>Isso te parece familiar?</Title>
                <Text>
                    Essa cena parece familiar? Quando finalmente você vai descansar, lembra de algo que pode ter dado errado 
                    e acaba perdendo o sono se preocupando. Com o curso passo a passo, você vai ter a consciência tranquila 
                    de poder descansar em paz.
                </Text>
                <Text>
                    Se você se identificou com um ou mais aspectos desse tópico, o curso é para você e terei o prazer de 
                    te guiar e facilitar seu sucesso profissional na área de TI.
                </Text>
            </Item>
            
            <ButtonReadContinueContent>
                <ButtonReadContinue onClick={handleOpenSubcontent}>
                    Continuar lendo
                </ButtonReadContinue>
            </ButtonReadContinueContent>

            {subContentVisible && (
                <>
                <SubContent>
                    <SubContentItem>
                        <SubContentItemText>Você quer ser um profissional requisitado no mercado de trabalho, com um arsenal de conhecimento e tecnologias de ponta?</SubContentItemText>
                    </SubContentItem>
                    <SubContentItem>
                        <SubContentItemText>Você quer elevar sua carreira para o próximo nível e ser preparado para definir a melhor arquitetura para hospedar qualquer tamanho de site ou sistema?</SubContentItemText>
                    </SubContentItem>
                    <SubContentItem>
                        <SubContentItemText>Você quer se tornar um especialista em infraestrutura Linux mais técnico, confiante, seguro de que está criando soluções do jeito certo, e ser preparado para encarar desafios de gente grande.</SubContentItemText>
                    </SubContentItem>
                </SubContent>
                <ButtonCTAContent>
                    <ButtonCTA href="/checkout" title="Fazer minha inscrição">Fazer minha inscrição</ButtonCTA>
                </ButtonCTAContent>
                </>
            )}
        </Container>
    );
}

export default MobileConvinceBlock;