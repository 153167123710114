import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import FaqItemComponent from '@components/FaqItemMobile';

import lineImage from '@src/images/limitado/desktop/Faq/line.png';

import {
    Container,
    ContentTitle,
    ContentTitleText,
    SeparatorLine,
    Content,
    Separator,
    Faq,
} from './styles';

const QUERY = graphql`
    query {
        faqs: allMarkdownRemark(filter: {frontmatter: {type: {eq: "faq"}}}) {
            edges {
                node {
                    frontmatter {
                        title
                    }
                    id
                    html
                }
            }
        }
    }  
`;

const MobileFaqBlock = () => {
    const faqs = useStaticQuery(QUERY);

    return (
    <Container>
        <ContentTitle>
            <ContentTitleText>Alguma dúvida?</ContentTitleText>
            <SeparatorLine src={lineImage} />
        </ContentTitle>
        <Content>
            <Faq>
                {faqs.faqs.edges.map(faq => <FaqItemComponent faq={faq} key={faq.node.id} /> )}
            </Faq>
        </Content>
    </Container>
    );
}

export default MobileFaqBlock;