import React from 'react';

import lineImage from '@src/images/line.png';
import icon01 from '@src/images/limitado/mobile/AboutTheCourse/icon01.png';
import icon02 from '@src/images/limitado/mobile/AboutTheCourse/icon02.png';
import icon03 from '@src/images/limitado/mobile/AboutTheCourse/icon03.png';
import icon04 from '@src/images/limitado/mobile/AboutTheCourse/icon04.png';

import Content from '@src/components/ContentDesktop';
import ButtonCTA from '@src/components/ButtonCTA';

import {
    Container,
    Col,
    ContentTitle,
    Title,
    SeparatorLine,
    Item,
    ItemImageContent,
    ItemImage,
    ItemContent,
    ItemTitle,
    ItemDescription,
    ButtonCTAContent,
} from './styles';

const DesktopAboutTheCourseBlock = () => {


    return (
        <Container>
            <Content>
                <Col>
                    <ContentTitle>
                        <Title>Sobre o curso</Title>
                        <SeparatorLine src={lineImage} />
                    </ContentTitle>
                    <Item>
                        <ItemImageContent>
                            <ItemImage src={icon01} />
                        </ItemImageContent>
                        <ItemContent>
                            <ItemTitle>Perca o medo do Linux</ItemTitle>
                            <ItemDescription>
                                Quer aprender do zero? Na verdade aprender Linux é mais simples do que você imagina, 
                                você vai conhecer e utilizar o shell, aquele terminal que muitos tem medo e ter a segurança 
                                que se seu servidor cair você vai saber o que aconteceu e solucionar. Não deixe seu 
                                servidor e seus códigos na mão de uma outra pessoa.
                            </ItemDescription>
                        </ItemContent>
                    </Item>
                    <Item>
                        <ItemImageContent>
                            <ItemImage src={icon02} />
                        </ItemImageContent>
                        <ItemContent>
                            <ItemTitle>Feito por quem entende</ItemTitle>
                            <ItemDescription>
                                Eu fico PUTO com a quantidade de charlatões que têm no mercado de TI, esse foi um dos fatores 
                                que me levou a criar o curso. Trabalho há décadas com programação e já passei por poucas 
                                e boas, vou transferir todo esse conhecimento para você não cometer os erros que eu cometi 
                                quando estava começando e me fizeram perder muito dinheiro.
                            </ItemDescription>
                        </ItemContent>
                    </Item>
                </Col>
                <Col>
                    <Item>
                        <ItemImageContent>
                            <ItemImage src={icon03} />
                        </ItemImageContent>
                        <ItemContent>
                            <ItemTitle>Servidor remoto em cloud</ItemTitle>
                            <ItemDescription>
                                Aqui não tem enrolação. Todos os materiais e vídeos pensados para facilitar o dia a dia 
                                de profissionais que trabalham na área de Infraestrutura WEB na Nuvem, AWS, DigitalOcean, 
                                Linode e outros.
                            </ItemDescription>
                        </ItemContent>
                    </Item>
                    <Item>
                        <ItemImageContent>
                            <ItemImage src={icon04} />
                        </ItemImageContent>
                        <ItemContent>
                            <ItemTitle>Um suporte gente boa =)</ItemTitle>
                            <ItemDescription>
                                Apesar de ter uma rotina extremamente corrida, vou separar uma parte do meu dia exclusivamente 
                                para atender e tirar dúvidas dos alunos sobre estratégias, materiais do curso. Só essa espécie 
                                de mentoria que você irá receber através do atendimento do whatsapp já paga o curso mais de 
                                10 vezes.
                            </ItemDescription>
                        </ItemContent>
                    </Item>
                    <ButtonCTAContent>
                        <ButtonCTA href="/checkout" title="Garantir meu acesso">Garantir meu acesso</ButtonCTA>
                    </ButtonCTAContent>
                </Col>
            </Content>
        </Container>
    );
}

export default DesktopAboutTheCourseBlock;