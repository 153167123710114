import styled from 'styled-components';

export const Container = styled.div`
    ${tw`bg-theme-background-block mb-12 p-5 pb-8 rounded`};
`;

export const Content = styled.div`
    ${tw``};
`;

export const ModuleNumberContent = styled.div`
    margin-top: -50px;
`;

export const ModuleNumberContentText = styled.p`
    ${tw`font-bold text-5xl text-theme-text-purple`};
`;

export const ContentInfo = styled.div`
    ${tw`flex mt-3`};
`;

export const Title = styled.h2`
    ${tw`font-bold flex-1 text-white content-center`};
    font-size: 17px;
`;

export const Icon = styled.div`
    ${tw`text-right content-center items-center align-center justify-center`};
    width: 40px;
    cursor:pointer;
`;

export const Message = styled.div`
    ${tw`mt-5 text-theme-text text-base leading-normal`};
    line-height: 1.5rem;
`;