import styled from 'styled-components';

export const Container = styled.div`
  ${tw`flex w-full pt-20 pb-20`};
`;

export const ContentPhone = styled.div`
  ${tw`flex-1 flex`};
`;

export const ContentSeparator = styled.div`
  ${tw`flex-1 flex`};
`;

export const ContentPhoneImage = styled.div`
  ${tw`text-center`};
`;

export const PhoneImage = styled.img`

`;

export const ContentPhoneText = styled.div`
  ${tw`w-5/6 ml-5 mt-1`};
`;

export const ContentInstagram = styled.div`
  ${tw`flex-1 flex`};
`;

export const ContentInstagramImage = styled.div`
  ${tw`text-center`};
`;


export const InstagramImage = styled.img`
  ${tw``};
`;

export const ContentInstagramText = styled.div`
  ${tw`w-5/6 ml-5 mt-1`};
`;

export const Title = styled.p`
  ${tw`text-xl font-bold`};
`;

export const Text = styled.p`
  ${tw`mt-1`};
`;

export const Link = styled.a`
  ${tw`no-underline text-theme-text`};
`;
