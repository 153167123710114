import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Button,
} from './styles';

const ButtonCTA = (props) => {
  return (
      <Container>
          <Button {...props}>
              {props.children}
          </Button>
      </Container>
    );
};

ButtonCTA.propTypes = {
  // children: PropTypes.node.isRequired,
}

export default ButtonCTA;
