import styled from 'styled-components';

export const Container = styled.div`
    ${tw`flex mt-20 mb-20`};
`;

export const Separator = styled.div`
    ${tw`w-1/6`};
`;


export const Content = styled.div`
    ${tw`w-full flex`};
`;

export const GridLeft = styled.div`
    ${tw`w-1/2 mr-5`};
`;

export const GridRight = styled.div`
    ${tw`w-1/2 p-5 ml-5 pr-12`};
`;

export const GarantiaImage = styled.img`
  ${tw`w-full`};
`;

export const Title = styled.h1`
    ${tw`w-full text-5xl font-bold`};
`;

export const SeparatorLine = styled.img`
    ${tw``};
`;

export const Paragraph = styled.p`
    ${tw`mt-5 leading-10 text-theme-text`};
    line-height: 1.5rem;
`;