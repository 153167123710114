import styled from 'styled-components';

export const Container = styled.div`
    ${tw`w-1/2 p-2 pl-4 pr-4`};
`;

export const Content = styled.div`
    ${tw`bg-theme-background-block rounded p-8 flex shadow-xl`};
`;

export const ModuleNumber = styled.div``;

export const ModuleNumberText = styled.p`
    ${tw`text-theme-text-purple font-bold`};
    margin-left: -45px;
    margin-top: -20px;
    font-size: 48px;
`;

export const ModuleContent = styled.div`
    ${tw`ml-4 flex-1`};
`;

export const Title = styled.h2`
    ${tw`text-xl font-bold`};
`;

export const Message = styled.p`
    ${tw`mt-3 text-theme-text-secundary text-base leading-normal`};
`;


export const Icon = styled.div``;

export const IconLink = styled.div`
    cursor: pointer;
`;
