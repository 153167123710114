import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ScrollHorizontal from 'react-scroll-horizontal';

import lineSeparatorImage from '@src/images/line.png';

import DepoimentItemComponent from '@components/DepoimentItem';
import ButtonCTA from '@components/ButtonCTA';

import {
    Container,
    ContentTitle,
    ContentTitleText,
    SeparatorLine,
    Slider,
    ContentCTA,
} from './styles';

const QUERY = graphql`
    query {
        depoiments: allMarkdownRemark(filter: {frontmatter: {type: {eq: "depoiments"}}}) {
            edges {
                node {
                    id
                    html
                    frontmatter {
                        title
                        name
                        photo{
                            childImageSharp{
                                fluid{
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DesktopDepoimentsBlock = () => {
    const depoiments = useStaticQuery(QUERY);

    return (    
        <Container>
            <ContentTitle>
                <ContentTitleText data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease">Depoimentos dos alunos</ContentTitleText>
                <SeparatorLine src={lineSeparatorImage} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" />
            </ContentTitle>

            <Slider>
                <ScrollHorizontal>
                    {depoiments.depoiments.edges.map(depoiment => <DepoimentItemComponent depoiment={depoiment} key={depoiment.node.id} />)}
                </ScrollHorizontal>
            </Slider>
  
            <ContentCTA>
                <ButtonCTA href="/checkout">Quero me tornar um Profissional em VPS</ButtonCTA>
            </ContentCTA>
        </Container>
    );
}

export default DesktopDepoimentsBlock;