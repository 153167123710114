import styled from 'styled-components';

export const Container = styled.div`
    ${tw`bg-theme-background-block mb-5 px-4 p-6 rounded`};
`;

export const Content = styled.div`
    ${tw`flex`};
`;

export const Title = styled.p`
    ${tw`font-bold flex-1 text-white content-center`};
    font-size: 17px;
`;

export const Icon = styled.div`
    ${tw`text-right content-center`};
    width: 40px;
    cursor:pointer;
`;

export const Message = styled.div`
    ${tw`mt-5 text-theme-text`};
    font-size: 15px;
    line-height: 1.5rem;
`;