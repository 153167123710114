import styled from 'styled-components';

export const Container = styled.div`
  ${tw``};
`;

export const AuthorImage = styled.img`
  ${tw`w-full`};
`;

export const Content = styled.div`
  ${tw`p-8`};
`;

export const Title = styled.div`
  ${tw`text-4xl font-bold text-white`};
`;

export const SeparatorLine = styled.img`
  ${tw`mt-5`};
`;

export const Text = styled.p`
  ${tw`mt-10 text-base font-medium text-theme-text-secundary leading-normal`};
`;

export const SignatureImage = styled.img`
  ${tw`w-2/6 mt-10 mb-20`};
`;

