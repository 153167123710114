import styled from 'styled-components';

export const Container = styled.div`
  ${tw`mb-20`}
`;

export const ContentTitle = styled.div`
  ${tw`flex-1 text-center`}
`;

export const Title = styled.h1`
  ${tw`text-5xl font-bold`}
`;

export const SeparatorImage = styled.img`
    ${tw`bg-green`}
`;

export const ContentDefault = styled.div`
   ${tw`w-full`}
`;

export const ContentDescription = styled.div`
  ${tw`w-full flex mt-20 text-theme-text-background`};
`;

export const ContentDescriptionSpan = styled.span`
  ${tw`text-theme-text-purple`};
`;

export const Col = styled.div`
  ${tw`w-1/2`};

  &:first-child {
    margin-right: 45px;
  }
`;

export const ContentDescriptionTitle = styled.h1`
  ${tw`text-5xl text-right`};
  font-size: 72px;
`;

export const ContentDescriptionText = styled.p`
  ${tw`text-theme-text leading-normal text-base mt-2 mr-10`};
`;

export const ContentItem = styled.div`
  ${tw`mt-20 flex flex-wrap w-full`};
`;

export const ContentBonus = styled.div`
  ${tw`w-1/2 p-2 pl-4 pr-4`};
`;

export const ContentBonusContent = styled.div`
  ${tw`bg-theme-background-block rounded p-8 flex`};
`;

export const BonusIcon = styled.div`
    ${tw`items-baseline`};
    margin-left: -45px;
    margin-top: -20px;
`;

export const BonusIconImage = styled.img`
  ${tw``};
`;

export const BonusContent = styled.div`
  ${tw`ml-4 flex-1`};
`;

export const BonusContentTitle = styled.div`
  ${tw`text-xl font-bold`};
`;

export const BonusContentMessage = styled.div`
  ${tw`mt-3 text-theme-text-secundary`};
`;

export const ContentNextIcon = styled.div`
  ${tw`w-full text-center`};
  position: relative;
  top: 110px;
`;

export const ContentNextIconImage = styled.img``;