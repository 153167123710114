import styled from 'styled-components';

export const Container = styled.div`
    ${tw`bg-theme-background-block w-1/2 my-2 px-4 p-6 rounded`};
    border-left: 10px solid #131217;
    border-right: 10px solid #131217;
    border-radius: 10px !important;
`;

export const Content = styled.div`
    ${tw`flex`};
`;

export const Title = styled.p`
    ${tw`font-bold flex-1 text-white content-center`};
    font-size: 17px;
`;

export const Icon = styled.div`
    ${tw`text-right content-center`};
    width: 40px;
    cursor:pointer;
`;

export const Message = styled.div`
    ${tw`mt-5 text-theme-text`};
    font-size: 15px;
    line-height: 1.5rem;
`;