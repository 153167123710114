import React from 'react';

import Content from '@src/components/ContentDesktop';

import certificateImage from '@src/images/limitado/desktop/Certificate/certificate.png';

import separatorImage from '@src/images/line.png';

import {
    Container,
    ContentColor,
    Left,
    CertificateImage,
    Right,
    Title,
    Text,
    SeparatorLine,
} from './styles';

const DesktopCertificateBlock = () => (
    <Container>
        <Content>
            <ContentColor>
                <Left>
                    <CertificateImage src={certificateImage} />
                </Left>
                <Right>
                    <Title data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">Certificado</Title>
                    <SeparatorLine src={separatorImage} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" />
                    <Text>
                        Ao finalizar o curso, como reconhecimento do seu empenho, irá poder emitir um certificado nominal 
                        que irá comprovar a participação e conclusão do seu curso. Isso vai lhe dar a autoridade necessária 
                        para mostrar que você é um profissional proativo, que está sempre em busca de ficar antenado das 
                        coisas mais recentes para entregar um trabalho de qualidade. Mas o mais importante mesmo é o sentimento 
                        de gratidão e lembrança pelo treinamento que mudou sua vida totalmente. Terão duas pessoas, você 
                        antes do curso e depois do curso.
                    </Text>
                </Right>
            </ContentColor>
        </Content>
    </Container>
);

export default DesktopCertificateBlock;