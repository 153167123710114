import styled from 'styled-components';

export const Container = styled.div`
    ${tw`bg-theme-background-block mb-5 p-8 flex-col`};
`;

export const Content = styled.div`
    ${tw`flex-1 text-center`};
`;

export const Content1 = styled.div`
    ${tw`w-full justify-center flex`};
`;

export const Logo = styled.img`
    ${tw`w-2/3 ml-5 mr-5`};
`;

export const ContentFullWidth = styled.div`
    ${tw`w-full justify-center mt-10 flex`};
`;

export const ButtonContent = styled.div`
    ${tw`w-1/2`};
`;