import React from 'react';
// import { FaYoutube } from 'react-icons/fa';
// import { FaInstagram } from 'react-icons/fa';


import {
    Container,
    Title,
    Text,
    Copyright,
    Rules,
} from './styles';

const DesktopFooterBlock = () => (
    <Container>
        {/* <Rules>Termos de uso | Política de privacidade</Rules> */}
        <Title>MÁRCIO DIAS - SOLUÇÕES DIGITAIS</Title>
        <Text>Rua Monte Santo 551 sala 03 - Santo Antônio - Divinópolis / Minas Gerais / Brasil</Text> 
        <Text>CNPJ: 23.269.415/0001-60</Text>
        <Copyright>© Copyright {new Date().getFullYear()} - Todos os Direitos Reservados</Copyright>
    </Container>
);

export default DesktopFooterBlock;