import styled from 'styled-components';

import btnBGImage from '@src/images/background-button.png';

export const Container = styled.div`
  ${tw`text-center`}
`;

export const Button = styled.a`
  ${tw`
    bg-theme-text-purple 
    hover:bg-gray-400
    hover:text-theme-background
    text-white 
    rounded-full 
    font-bold py-5 
    px-10 
    no-underline
    shadow
  `};

  background-image: url(${btnBGImage});
  background-repeat: no-repeat;
  background-position: right -3px;
  cursor:pointer;

  transition: color 0.2s;

  &:hover {
    color: #383838;
    background-color: #00D3AE;
  }

`;
