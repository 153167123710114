import React from 'react';

import lineImage from '@src/images/line.png';
import icon1 from '@src/images/limitado/desktop/ForWhois/icon1.png';
import icon2 from '@src/images/limitado/desktop/ForWhois/icon2.png';
import icon3 from '@src/images/limitado/desktop/ForWhois/icon3.png';
import icon4 from '@src/images/limitado/desktop/ForWhois/icon4.png';

import Content from '@src/components/ContentDesktop';

import {
    Container,
    ContentPage,
    ContentTitle,
    ContentTitleText,
    ContentTitleSeparator,
    ContentItems,
    ItemtInitial,
    ItemSecundary,
    IconImage,
    ContentItemInitial,
    ContentItemInitialTitle,
    ContentItemInitialDescription,
    ContentItemsCol,
    ItemThree,
    ItemFour,
} from './styles';

const DesktopForWhoisBlock = () => (
    <Container>
        <Content>
            <ContentPage>
                <ContentTitle>
                    <ContentTitleText>Para quem é</ContentTitleText>
                    <ContentTitleSeparator src={lineImage} />
                </ContentTitle>
                <ContentItems>
                    <ContentItemsCol>
                        <ItemtInitial>
                            <IconImage src={icon1} />
                            <ContentItemInitial>
                                <ContentItemInitialTitle>Estudantes de TI</ContentItemInitialTitle>
                                <ContentItemInitialDescription>Sejam iniciantes ou não</ContentItemInitialDescription>
                            </ContentItemInitial>
                        </ItemtInitial>
                        <ItemSecundary>
                            <IconImage src={icon2} />
                            <ContentItemInitial>
                                <ContentItemInitialTitle>Revendedores de hospedagem</ContentItemInitialTitle>
                                <ContentItemInitialDescription>Que queiram ganhar mais dinheiro</ContentItemInitialDescription>
                            </ContentItemInitial>
                        </ItemSecundary>
                    </ContentItemsCol>
                </ContentItems>
                <ContentItems>
                    <ContentItemsCol>
                        <ItemThree>
                            <IconImage src={icon3} />
                            <ContentItemInitial>
                                <ContentItemInitialTitle>Interessados em infraestrutura</ContentItemInitialTitle>
                                <ContentItemInitialDescription>Ou aprender sobre serviços na nuvem</ContentItemInitialDescription>
                            </ContentItemInitial>
                        </ItemThree>
                        <ItemFour>
                            <IconImage src={icon4} />
                            <ContentItemInitial>
                                <ContentItemInitialTitle>Donos de sites</ContentItemInitialTitle>
                                <ContentItemInitialDescription>Que desejam ter mais autonomia</ContentItemInitialDescription>
                            </ContentItemInitial>
                        </ItemFour>
                    </ContentItemsCol>
                </ContentItems>
            </ContentPage>
        </Content>
    </Container>
);

export default DesktopForWhoisBlock;