import React, { useState } from 'react';

import Content from '@components/ContentDesktop';
import ButtonCTA from '@components/ButtonCTA';

import image01 from '@src/images/limitado/mobile/Convince/01.png';
import image02 from '@src/images/limitado/mobile/Convince/02.png';
import image03 from '@src/images/limitado/mobile/Convince/03.png';

import {
    Container,
    ItemContent,
    Item,
    Left,
    Right,
    Image,
    Title,
    Text,
    ButtonReadContinueContent,
    ButtonReadContinue,
    SubReadContent,
    SubReadContentText,
    ButtonCTAContent,
    SubReadContentTextColor,
} from './styles';

const DesktopConvinceBlock = () => {
    const [subContentVisible, setSubContentVisible] = useState(false);

    const handleOpenSubcontent = () => {
        setSubContentVisible(!subContentVisible);
    }

    return  (
        <Container>
            <Content>
                <ItemContent>
                    <Item>
                        <Left>
                            <Image src={image01} alt="Situação 01" />
                        </Left>
                        <Right>
                            {/* <Title>Você está trazendo uma faca de <br />mesa pra um tiroteiro?</Title> */}
                            <Title>Embarque nesse foguete e comece a avançar agora mesmo na direção dos seus objetivos.</Title>
                            <Text>
                                O mercado de TI está cada dia mais prostituído, a maioria dos profissionais não 
                                são valorizados. Mas tem algo interessante, há pouquíssimas pessoas ganhando 
                                MUITO dinheiro com essa área.
                            </Text>
                            <Text>Com esse curso você terá o passo a passo prático para desvendar o mapa do tesouro.</Text>
                            <Text>Está cansado de se sentir estagnado, de desconfiar que seus concorrentes sabem mais do que você?</Text>
                            <Text>
                                Infelizmente é verdade, você sabe, as pessoas adoram reclamar, e estão sendo "escondendo o ouro", 
                                tem muito concorrente seu se fazendo de bobo e enchendo os bolsos de dinheiro.
                            </Text>
                        </Right>
                    </Item>
                    <Item>
                        <Left>
                            <Title>Eu acredito que você quer se <br /> sentir realizado com o que faz.</Title>
                            <Text>
                                Eu acredito que você quer se sentir realizado com aquilo que faz e não ser apenas um 
                                fardo para pagar as contas.
                            </Text>
                            <Text>
                                E o melhor, ganhar muito bem! Podendo contratar funcionários quando estiver maior e 
                                fazendo sua empresa funcionar no piloto automático, com você apenas monitorando os 
                                funcionários, possibilitando curtir mais a vida com sua família, nosso maior tesouro.
                            </Text>
                            <Text>
                                E por fim, vai aprender além de tudo, a ganhar confiança, perder aquele medo de fazer 
                                algo errado e colocar tudo a perder que você fez em dias (que podem ser semanas, meses 
                                ou até mesmo anos dependendo do seu projeto).
                            </Text>
                        </Left>
                        <Right>
                            <Image src={image02} alt="Situação 01" />
                        </Right>
                    </Item>
                    <Item>
                        <Left>
                            <Image src={image03} alt="Isso te parece familiar?" />
                        </Left>
                        <Right>
                            <Title>Isso te parece familiar?</Title>
                            <Text>
                                Essa cena parece familiar? Quando finalmente você vai descansar, lembra de algo que pode 
                                ter dado errado e acaba perdendo o sono se preocupando. Com o curso passo a passo, 
                                você vai ter a consciência tranquila de poder descansar em paz.
                            </Text>
                            <Text>
                                Se você se identificou com um ou mais aspectos desse tópico, o curso é para você 
                                e terei o prazer de te guiar e facilitar seu sucesso profissional na área de TI.
                            </Text>
                            <ButtonReadContinueContent data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
                                <ButtonReadContinue onClick={handleOpenSubcontent}>
                                    Continuar lendo
                                </ButtonReadContinue>
                            </ButtonReadContinueContent>
                        </Right>
                    </Item>
                </ItemContent>
            </Content>

            {subContentVisible && (
                <>
                    <Content>
                        <SubReadContent>
                            <SubReadContentText>
                                Você quer ser um <SubReadContentTextColor>profissional requisitado</SubReadContentTextColor> no mercado de trabalho, com um arsenal de conhecimento e tecnologias de ponta?
                            </SubReadContentText>
                        </SubReadContent>             
                        <SubReadContent>
                            <SubReadContentText>
                                Você quer <SubReadContentTextColor>elevar sua carreira</SubReadContentTextColor> para o próximo nível e ser preparado para definir a melhor arquitetura para hospedar qualquer tamanho de site ou sistema?
                            </SubReadContentText>
                        </SubReadContent>
                        <SubReadContent>
                            <SubReadContentText>
                                Você quer se tornar um <SubReadContentTextColor>especialista em infraestrutura Linux</SubReadContentTextColor> mais técnico, confiante, seguro de que está criando soluções do jeito certo, e ser preparado para encarar desafios de gente grande.
                            </SubReadContentText>
                        </SubReadContent>
                    </Content>
                    <ButtonCTAContent>
                        <ButtonCTA href="/checkout" title="Garantir minha vaga">
                            Garantir minha vaga
                        </ButtonCTA>
                    </ButtonCTAContent>
                </>
            )}
        </Container>
    );
}

export default DesktopConvinceBlock;