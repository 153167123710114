import React, { useEffect, useState } from 'react';

import hotmartIcon from '@src/images/limitado/desktop/Cta/hotmart-icon.png';

import CTACoundDownComponent from '@components/CTACountDown';

import {
    Container,
    Separator,
    Content,
    CountDown,
    CountDownTitle,
    ContentCTA,
    Title,
    Subtitle,
    Price,
    PriceSmall,
    Description,
    ButtonContent,
    ButtonCTA,
    HotmartContent,
    HotmartText,
    HotmartIcon,
} from './styles';

const DesktopCtaBlock = () => (
        <Container>
            <Separator />
                <Content>
                    <CountDown>
                        <CountDownTitle>Essa oferta se encerra em</CountDownTitle>
                        <CTACoundDownComponent />
                    </CountDown>
                    <ContentCTA>
                        <Title>Domine a infraestrutura <br />em servidores VPS</Title>
                        <Subtitle>por apenas:</Subtitle>
                        <Price>
                            <PriceSmall>R$</PriceSmall> 297
                        </Price>
                        <Description>
                            Ou em até 10x de R$29,70.
                        </Description>
                        <ButtonContent>
                            <ButtonCTA href="/checkout" target="_blank">
                                Fazer inscrição agora
                            </ButtonCTA>
                        </ButtonContent>
                        <HotmartContent>
                            <HotmartText>Compra segura via</HotmartText>
                            <HotmartIcon src={hotmartIcon} alt="Hotmart" />
                        </HotmartContent>
                    </ContentCTA>
                </Content>
            <Separator />
        </Container>
    )

export default DesktopCtaBlock;