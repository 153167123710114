import styled from 'styled-components';

import backgroundAuthor from '@src/images/limitado/desktop/Author/marcio.png';

export const Container = styled.div`
    ${tw`flex`};
`;

export const Left = styled.div`
    ${tw`w-1/2`};
`;

export const Right = styled.div`
    ${tw`w-1/2 mr-20 mt-20 pr-20`};
    background: url(${backgroundAuthor}) no-repeat;
`;


export const Title = styled.h1`
    ${tw`text-5xl font-bold mt-10`};
`;

export const SeparatorLine = styled.img`
    ${tw``};
`;


export const Text = styled.p`
  ${tw`mt-5 text-theme-text leading-normal text-base mr-20`};
`;

export const SignatureImage = styled.img`
    ${tw`mt-12`};
`;