import React from 'react';

import lineImage from '@src/images/line.png';
import accessImage from '@src/images/limitado/mobile/Access/access.png';

import ButtonCTA from '@components/ButtonCTA';

import {
    Container,
    IconImage,
    Title,
    Separator,
    Content,
    Text,
    ButtonContent,
} from './styles';

const MobileAccessBlock = () => (
    <Container>
        <IconImage src={accessImage} />
        <Content>
            <Title>Seu acesso</Title>
            <Separator src={lineImage} />
            <Text>Poucos instantes depois que você efetuar o pagamento (boleto pode levar até 2 dias úteis para compensar) você irá receber seu acesso único e exclusivo no seu e-mail pessoal. Podendo já matar a curiosidade do conteúdo e aprender para colocar em prática.</Text>
            <Text>O melhor é que o curso é totalmente online. Você irá economizar com deslocamento e outras coisas caso fosse um curso presencial. E a cereja do bolo, poderá assistir quantas vezes quiser as aulas com acesso vitalício, sem se preocupar com qualquer limite.</Text>
        </Content>
        <ButtonContent>
            <ButtonCTA href="/checkout">Garantir meu acesso</ButtonCTA>
        </ButtonContent>
    </Container>
);

export default MobileAccessBlock;