import React from 'react';

import logoImage from '@src/images/marca.svg';

import Content from '@src/components/ContentDesktop';

import ButtonLogin from '@src/components/ButtonLogin';

import {
    Container,
    Footer,
    LogoContent,
    LogoContentImage,
    MenuContent,
    Menu,
    MenuItem,
    MenuItemLink,
    LoginContent,
} from './styles';

const DesktopFooterMenuBlock = () => (
    <Container>
        <Content>
            <Footer>
                <LogoContent>
                    <LogoContentImage src={logoImage} alt="VPS do Zero ao Avançado" />
                </LogoContent>
                <MenuContent>
                    <Menu>
                        <MenuItem>
                            <MenuItemLink href="#">Sobre o curso</MenuItemLink>
                        </MenuItem>
                        <MenuItem>
                            <MenuItemLink href="#">Módulos</MenuItemLink>
                        </MenuItem>
                        <MenuItem>
                            <MenuItemLink href="#">Tecnologias</MenuItemLink>
                        </MenuItem>
                        <MenuItem>
                            <MenuItemLink href="#">Seu piloto</MenuItemLink>
                        </MenuItem>
                        <MenuItem>
                            <MenuItemLink href="#">Certificado</MenuItemLink>
                        </MenuItem>
                        <MenuItem>
                            <MenuItemLink href="#">Depoimentos</MenuItemLink>
                        </MenuItem>
                    </Menu>
                </MenuContent>
                <LoginContent>
                    <ButtonLogin href="/curso" title="Acesso aluno">
                        Acesso aluno
                    </ButtonLogin>
                </LoginContent>
            </Footer>
        </Content>
    </Container>
);

export default DesktopFooterMenuBlock;