import React from 'react';

import authorImage from '@src/images/limitado/mobile/Author/author.png';
import lineImage from '@src/images/line.png';
import signatureImage from '@src/images/limitado/mobile/Author/signature.png';

import {
    Container,
    AuthorImage,
    Content,
    Title,
    SeparatorLine,
    Text,
    SignatureImage,
} from './styles';

const MobileAuthorBlock = () => (
    <Container>
        <AuthorImage src={authorImage} />
        <Content>
            <Title>Seu piloto</Title>
            <SeparatorLine src={lineImage} />
            <Text>Sou Programador há mais de 15 anos e apaixonado por tecnologias de desenvolvimento web. Atuo com foco em back-end, front-end e mobile, sou co- fundador e CTO na empresa Márcio Dias - Soluções Digitais tendo vários clientes recorrentes que todo mês colocam grana no bolso da minha empresa.</Text>
            <Text>Minha missão é retribuir para o mundo a mudança que o linux e programação trouxeram para minha vida. De um jovem sem perspectiva de futuro e altamente inseguro, consegui me consolidar financeiramente, e o melhor, profissionalmente. Nada é mais satisfatório do que você fazer o que gosta e ser recompensado bem financeiramente por isso, podendo dar para sua família o que eles merecem, que seus filhos não passem as privações que um dia você pode ter passado.</Text>
            <SignatureImage src={signatureImage} />
        </Content>
    </Container>
);

export default MobileAuthorBlock;