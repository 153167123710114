import styled from 'styled-components';

export const Container = styled.div`
  ${tw`p-8 pt-20 pb-20 bg-white`};
`;

export const ContentTitle = styled.div`
  ${tw`text-left`};
`;

export const ContentTitleText = styled.h1`
  ${tw`text-3xl`};
  color: grey;
`;

export const ContentTitleSeparator = styled.img`
  ${tw``};
`;

export const ContentTitleMessage = styled.div`
  ${tw`text-center`};
`;

export const Content = styled.div`
  ${tw`mt-20`};
  /* ${tw`mt-10 bg-red grid grid-flow-col grid-cols-3 grid-rows-3 gap-4`}; */
`;

export const IconImage = styled.img`
  ${tw`mb-10 w-full`};
`;

export const Separator = styled.hr`
  ${tw`mt-20`};
  border: 1px solid #EFEAF7;
`;