import React from 'react';

import phoneImage from '@src/images/limitado/desktop/FooterMenu/phone.png';
import instagramImage from '@src/images/limitado/desktop/FooterMenu/instagram.png';

import {
    Container,
    Content,
    PhoneImage,
    PhoneContent,
    Title,
    Text,
} from './styles';

const MobileFooterContactBlock = () => (
    <Container>
        <Content>
            <PhoneImage src={instagramImage} />
            <PhoneContent>
                <Title>Instagram</Title>
                <Text>@marciodias.me</Text>
            </PhoneContent>
        </Content>
        <Content>
            <PhoneImage src={phoneImage} />
            <PhoneContent>
                <Title>Telefone</Title>
                <Text>(37) 3212-8406</Text>
            </PhoneContent>
        </Content>
    </Container>
);

export default MobileFooterContactBlock;