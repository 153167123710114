import styled from 'styled-components';

import authorImage from '@src/images/limitado/desktop/Cta/author-new.png';
import courseImage from '@src/images/limitado/desktop/Cta/course-new.png';

export const Container = styled.div`
  ${tw`flex pb-10`};
  background-color: #212028 !important;
  background: url(${authorImage}) right bottom no-repeat, url(${courseImage}) left 120px no-repeat;
`;

export const Separator = styled.div`
  ${tw`flex-1`};
`;

export const Content = styled.div`
  ${tw`w-1/3 mt-20 text-center`};
`;

export const CountDown = styled.div`
  ${tw``};
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #1E1D23;
`;

export const CountDownTitle = styled.p`
  ${tw``};
  font-size: 18px;
`;

export const ContentCTA = styled.div`
  ${tw`p-10 mt-9`};
`;

export const Title = styled.h2`
  ${tw`font-medium`};
  font-size: 30px;
`;

export const Subtitle = styled.p`
  ${tw`font-medium text-theme-text-purple mt-3`};
  font-size: 25px;
`;

export const Price = styled.h1`
  ${tw`font-bold text-theme-text-green mt-10`};
  font-size: 95px;
`;

export const PriceSmall = styled.small`
  ${tw`font-bold text-theme-text-green`};
  font-size: 35px;
  margin-right: -15px;
`;

export const Description = styled.p`
  ${tw`text-theme-text`};
  font-size: 19px;
`;

export const ButtonContent = styled.div`
  ${tw`mt-16`};
`;

export const ButtonCTA = styled.a`
  ${tw`theme-text-button text-2xl`};

  background-color: #00D3AE;
  border-bottom: 5px solid #148672;
  border-radius: 30px;
  padding: 25px;
  padding-left: 60px;
  padding-right: 60px;
  color: #11574A;
  text-decoration: none;

  transition: color 0.3s;

  &:hover {
    color: #fff;
    background-color: #8249E2;
    border-bottom: 5px solid #11574A;
  }
`;

export const HotmartContent = styled.div`
  ${tw`mt-16`};
`;

export const HotmartText = styled.p`
  ${tw`text-lg`};
  color:#474655;
`;

export const HotmartIcon = styled.img`
  ${tw`mt-3`};
  /* background-color: red; */
  /* width: 80px; */
`;