import React from 'react';

import garantiaImage from '@src/images/limitado/desktop/Garantia/background-left@2x.png';

import lineImage from '@src/images/limitado/desktop/Faq/line.png';

import {
    Container,
    GarantiaImage,
    Content,
    Title,
    SeparatorLine,
    Text,
    Separator,
} from './styles';

const MobileFaqBlock = () => (
    <Container>
        <GarantiaImage src={garantiaImage} alt="Garantia de 7 dias." />
        <Content>
            <Title>Garantia de 7 dias</Title>
            <SeparatorLine src={lineImage} alt="Garantia de 7 dias" />
            <Text>Eu estou entregando tanto conteúdo de corpo de alma nesse curso que ofereço uma garantia incondicional. Se você não gostar do curso por qualquer motivo, com um simples e-mail você recebe o estorno integral do seu curso sem nenhuma pergunta e sem nenhum ressentimento. O risco por você comprar é todo meu. Você compra e só depois decide.</Text>
            <Text>Você tem 7 dias corridos de garantia e a qualquer momento pode pedir seu investimento de volta. A última coisa que quero é ficar com seu investimento e você ficar infeliz. Este é o nosso acordo.</Text>
        </Content>
        <Separator />
    </Container>

);

export default MobileFaqBlock;