import React from 'react';

import ContainerMobile from '@components/ContainerMobile';

import Intro from './Intro';
import ForWhom from './ForWhom';
import Convince from './Convince';
import AboutTheCourse from './AboutTheCourse';
import Modules from './Modules';
import Technologies from './Technologies';
import Author from './Author';
import Access from './Access';
import Certificate from './Certificate';
import Bonus from './Bonus';
import Cta from './Cta';
import Guarantee from './Guarantee';
import Depoiments from './Depoiments';
import Faq from './Faq';
import FooterContact from './FooterContact';
import FooterMenu from './FooterMenu';
import Footer from './Footer';

const Mobile = () => (
    <ContainerMobile>
        <Intro />
        <ForWhom />
        <Convince />
        <AboutTheCourse />
        <Modules />
        <Technologies />
        <Access />
        <Author />
        <Certificate />
        <Bonus />
        <Cta />
        <Guarantee />
        <Depoiments />
        <Faq />
        <FooterContact />
        <FooterMenu />
        <Footer />
    </ContainerMobile>
);

export default Mobile;