import styled from 'styled-components';

import buttonCTABackgroundImage from '@src/images/limitado/desktop/Depoiments/btnCTA.png';
import titleBackgroundImage from '@src/images/limitado/desktop/Depoiments/background-title.png';

export const Container = styled.div`

`;

export const ContentTitle = styled.div`
    ${tw`w-full mt-20 text-center bg-center`};
    background-image: url(${titleBackgroundImage});
    background-repeat: no-repeat;
    font-size: 28px;
    height: 120px;
`;

export const ContentTitleText = styled.h1`
    ${tw`text-5xl font-bold text-white`};
    padding-top: 70px;
`;

export const SeparatorLine = styled.img`

`;

export const Slider = styled.div`
    ${tw`ml-20 mt-20`};
    height: 215px;
    /* width: 100%; */
`;

export const ContentCTA = styled.div`
    ${tw`w-full text-center mt-20 mb-20`};
`;

export const ButtonCTA = styled.a`
    ${tw`text-white text-center`};
    background-image: url(${buttonCTABackgroundImage});
    background-repeat: no-repeat;
    padding: 30px;
`;