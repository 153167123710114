import React from 'react';

import authorImage from '@src/images/limitado/desktop/Author/author.png';
import separatorImage from '@src/images/line.png';
import signatureImage from '@src/images/limitado/desktop/Author/signature.png';

// import { GrLaunch } from 'react-icons/gr';

import {
    Container,
    Left,
    Right,
    Title,
    Text,
    SeparatorLine,
    SignatureImage,
} from './styles';

const DesktopAuthorBlock = () => (
    <Container>
        <Left>
            <img src={authorImage} alt="Márcio Dias" />
        </Left>
        <Right>
            <Title data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">Seu piloto</Title>
            <SeparatorLine src={separatorImage} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" />
            <Text>
                Sou Programador há mais de 15 anos e apaixonado por tecnologias de desenvolvimento web.  
                Atuo com foco em back-end, front-end e mobile, sou co-fundador e CTO na empresa 
                Márcio Dias - Soluções Digitais tendo vários clientes recorrentes que todo mês colocam grana no 
                bolso da minha empresa.
            </Text>
            <Text>
                Minha missão é retribuir para o mundo a mudança que o linux e programação trouxeram para minha vida. 
                De um jovem sem perspectiva de futuro e altamente inseguro, consegui me consolidar financeiramente, 
                e o melhor, profissionalmente. Nada é mais satisfatório do que você fazer o que gosta e ser recompensado 
                bem financeiramente por isso, podendo dar para sua família o que eles merecem, que seus filhos não 
                passem as privações que um dia você pode ter passado.
            </Text>
            <SignatureImage src={signatureImage} />
        </Right>
    </Container>
);

export default DesktopAuthorBlock;